import styles from "./footer.styles.module.css";
import logo from "../../assets/logo.svg";
import { text } from "../../text";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context/language.context";
import { NavLink, useNavigate } from "react-router-dom";
import { Icon } from "../icon/icon.component";
export default function FooterComponent() {
  const { lang } = useContext(LanguageContext);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.first_container}>
        <div className={styles.column_first_container}>
          <h5>{text.learn_about_yourself[lang]}</h5>
          <NavLink to={"/"}>{text.my_professional_profile[lang]}</NavLink>
          <NavLink to={"/"}>{text.professional_success[lang]}</NavLink>
        </div>
        <div className={styles.column_first_container}>
          <h5>{text.finding_opportunities[lang]}</h5>
          <NavLink to={"/"}>{text.occupations[lang]}</NavLink>
          <NavLink to={"/"}>{text.occupations[lang]}</NavLink>
          <NavLink to={"/"}>{text.sectors[lang]}</NavLink>
        </div>
        <div className={styles.column_first_container}>
          <h5>{text.help_others[lang]}</h5>
          <NavLink to={"/"}>{text.professional_success[lang]}</NavLink>
          <NavLink to={"/"}>{text.professional_success[lang]}</NavLink>
        </div>
      </div>
      <div className={styles.second_container}>
        <div className={styles.container_second_container}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="logo" width={"100px"} />
            <p
              style={{
                cursor: "pointer",
                fontSize: "12px",
                color: "#a6a6a6",
              }}
            >
              ©{new Date().getFullYear()}. zypl.career.{" "}
              {text.copyright_all_rights_reserved[lang]}
            </p>
          </div>

          <div className={styles.links}>
            <NavLink to={"/"}>
              <Icon name={"fa-brands fa-vk"} size="1.6" />
            </NavLink>
            <NavLink to={"/"}>
              <Icon name={"fa-brands fa-telegram"} size="1.6" />
            </NavLink>
            <NavLink to={"/"}>
              <Icon name={"fa-brands fa-instagram"} size="1.6" />
            </NavLink>
            <NavLink to={"/"}>
              <Icon name={"fa-brands fa-youtube"} size="1.6" />
            </NavLink>
            <NavLink to={"/"}>
              <Icon name={"fa-brands fa-facebook-f"} size="1.6" />
            </NavLink>
          </div>

          <div>
            <p
              style={{
                cursor: "pointer",
                fontSize: "12px",
                color: "#a6a6a6",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              {text.user_agreement[lang]}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
