import React, { createContext } from "react";
import { UserType } from "../../type/type.ts";

const initialAuthState: UserType | null = null;

export const AuthContext = createContext<{
  authData: UserType | null;
  setAuthData: React.Dispatch<React.SetStateAction<UserType | null>>;
}>({
  authData: initialAuthState,
  setAuthData: () => null,
});
