import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import styles from "./button.styles.module.css";
import { memoComponent } from "../../util/memo-component.util";

export const Button = memoComponent(
  "Button",
  function ({
    children,
    mode = "primary",
    ...otherProps
  }: PropsWithChildren<
    {
      mode?:
        | "primary"
        | "secondary"
        | "simple"
        | "border"
        | "success"
        | "danger";
    } & React.ButtonHTMLAttributes<HTMLButtonElement>
  >) {
    return (
      <button
        {...otherProps}
        className={classNames(otherProps.className, {
          [styles.button]: true,
          [styles.classic]:
            mode === "primary" ||
            mode === "secondary" ||
            mode === "success" ||
            mode === "danger" ||
            mode === "simple" ||
            mode === "border",
          [styles.simple]: mode === "simple",
          [styles.border]: mode === "border",
          [styles.primary]: mode === "primary",
          [styles.secondary]: mode === "secondary",
          [styles.success]: mode === "success",
          [styles.danger]: mode === "danger",
        })}
      >
        {children}
      </button>
    );
  },
);
