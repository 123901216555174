import styles from "./alert.styles.module.css";
import React from "react";
import classNames from "classnames";
import { Icon } from "../icon/icon.component";
import { AlertOptions } from "./alert.context";

export function Alert(props: {
  isOpened: boolean;
  close: () => void;
  options: AlertOptions;
  style?: React.CSSProperties;
}) {
  // ---------------------------------------------------------------------------
  return (
    <div
      id="overlay"
      className={classNames({
        [styles.overlay]: true,
        [styles.opened]: props.isOpened,
        [styles.closed]: !props.isOpened,
      })}
      style={props.style}
    >
      <div
        className={classNames({
          [styles.simple]: props.options.mode === "simple",
          [styles.regular]: props.options.mode === "regular",
          [styles.success]: props.options.mode === "success",
          [styles.danger]: props.options.mode === "danger",
        })}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {props.options.icon && (
            <div
              className={classNames({
                [styles.regularIconWrap]: props.options.mode === "regular",
                [styles.successIconWrap]: props.options.mode === "success",
                [styles.dangerIconWrap]: props.options.mode === "danger",
              })}
            >
              <Icon
                className={classNames({
                  [styles.regularIcon]: props.options.mode === "regular",
                  [styles.successIcon]: props.options.mode === "success",
                  [styles.dangerIcon]: props.options.mode === "danger",
                })}
                name={props.options.icon}
              />
            </div>
          )}
          <div
            className={classNames({
              [styles.simpleText]: props.options.mode === "simple",
              [styles.regularText]: props.options.mode === "regular",
              [styles.successText]: props.options.mode === "success",
              [styles.dangerText]: props.options.mode === "danger",
            })}
          >
            {props.options.text}
          </div>
        </div>
        {props.options.mode !== "simple" && (
          <div className={styles.crossButtonContainer}>
            <Icon
              className={styles.crossButton}
              name="fa-solid fa-x"
              onClick={props.close}
            />
          </div>
        )}
      </div>
    </div>
  );
}
