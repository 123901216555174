import styles from "./header-container.styles.module.css";
import MenuComponent from "../menu/menu.component.tsx";
import { Button } from "../button/button.component.tsx";
import logo from "../../assets/logo.svg";
import SelectLanguageComponent from "../select-language/select-language.component.tsx";
import { Icon } from "../icon/icon.component.tsx";
import { useSidebar } from "../sidebar";
import { useNavigate } from "react-router-dom";
import PopperComponent from "../popper/popper.component.tsx";
import { useContext } from "react";
import { AuthContext } from "../../context/auth-context/auth.context.tsx";
export default function HeaderContainerComponent() {
  const sidebar = useSidebar();
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <div className={styles.logo} onClick={() => navigate("/")}>
          <img src={logo} alt={"logo"} width={"100px"} />
        </div>
        <div className={styles.button_menu_container}>
          <MenuComponent />
          <div className={styles.button_container}>
            <Button
              className={styles.button_for_sidebar}
              mode={"simple"}
              onClick={() => {
                sidebar.open({
                  width: "20",
                  closeOnEsc: true,
                  closeOnOutsideClick: true,
                });
              }}
            >
              <Icon name={"fa-solid fa-bars"} size={"small"} />
            </Button>
          </div>
          {authData && <PopperComponent />}
          <SelectLanguageComponent />
        </div>
      </div>
    </div>
  );
}
