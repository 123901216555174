import React, { useState } from "react";
import { UserType } from "../../type/type.ts";
import { AuthContext } from "./auth.context.tsx";
import { decryptString } from "../../util/decrypt-encrypt-string.ts";
import { AppConfig } from "../../app/app.config.ts";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const encryptDataLocalStorage = localStorage.getItem("auth");
  const decrypt = encryptDataLocalStorage
    ? JSON.parse(decryptString(encryptDataLocalStorage, AppConfig.jwtSecret))
    : null;
  const [authData, setAuthData] = useState<UserType | null>(decrypt);
  return (
    <AuthContext.Provider value={{ authData, setAuthData }}>
      {children}
    </AuthContext.Provider>
  );
}
