import { decryptString } from "./decrypt-encrypt-string.ts";
import { AppConfig } from "../app/app.config.ts";
export async function sendPostRequest(url: string, data?: object) {
  try {
    const userGetLocalStorage = localStorage.getItem("user");
    const decrypt = userGetLocalStorage
      ? JSON.parse(decryptString(userGetLocalStorage, AppConfig.jwtSecret))
      : undefined;
    const token = decrypt && decrypt.token;
    return await fetch(AppConfig.host + url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
  } catch (e) {
    console.log(e);
  }
}
