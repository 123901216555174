import * as React from "react";
import Popover from "@mui/material/Popover";
import { useContext, useState } from "react";
import styles from "./popper.styles.module.css";
import { Link } from "react-router-dom";
import { Icon } from "../icon/icon.component.tsx";
import { LanguageContext } from "../../context/language-context/language.context.tsx";
import { text } from "../../text.tsx";
import { AuthContext } from "../../context/auth-context/auth.context.tsx";

export default function PopperComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { lang } = useContext(LanguageContext);
  const { setAuthData } = useContext(AuthContext);

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // ---------------------------------------------------------------------------
  return (
    <div>
      <button className={styles.button_user} onClick={handleClick}>
        <Icon name={"fa-regular fa-circle-user"} size={"small"} />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ul className={styles.popper_ul}>
          <li className={styles.popper_li}>
            <Link
              to={"/account"}
              className={styles.popper_link}
              onClick={handleClose}
            >
              <Icon name={"fa-solid fa-user"} size={"1.6"} />
              <span className={styles.popper_span}>{text.my_page[lang]}</span>
            </Link>
          </li>

          <li className={styles.popper_li}>
            <Link
              to={"/"}
              className={styles.popper_link}
              onClick={() => {
                handleClose();
                localStorage.clear();
                setAuthData(null);
              }}
            >
              <Icon
                name={"fa-solid fa-arrow-right-from-bracket"}
                size={"1.6"}
              />
              <span className={styles.popper_span}>Log out</span>
            </Link>
          </li>
        </ul>
      </Popover>
    </div>
  );
}
