import { MouseEventHandler } from "react";
import styles from "./card.styles.module.css";
export default function Card(props: {
  component: JSX.Element;
  onClick?: MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <div className={styles.container} onClick={props.onClick}>
      {props.component}
    </div>
  );
}
