import { ReactNode } from "react";
import classNames from "classnames";
import styles from "./icon.styles.module.css";
import { PopupOpenerProps } from "../popup/popup.types";
import { PopupOpener } from "../popup/popup-opener.provider";

export function Icon({
  hidden,
  className,
  name,
  size = "medium",
  bg = false,
  danger = false,
  onClick,
  onMenu,
  popupOpenerProps,
  style = {},
}: {
  hidden?: boolean;
  className?: string;
  name: string;
  size?:
    | "tiny"
    | "small"
    | "medium"
    | "large"
    | "2.4"
    | "2.2"
    | "1.6"
    | "1"
    | "auto";
  bg?: boolean;
  danger?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => any;
  onMenu?: (() => ReactNode) | ReactNode;
  popupOpenerProps?: Partial<PopupOpenerProps>;
  style?: React.CSSProperties;
}) {
  const fontSize =
    size === "tiny"
      ? "0.9rem"
      : size === "small"
      ? "1.4rem"
      : size === "medium"
      ? "1.8rem"
      : size === "large"
      ? "3.4rem"
      : size === "2.4"
      ? "2.4rem"
      : size === "2.2"
      ? "2.2rem"
      : "auto"
      ? undefined
      : size + "rem";

  const Icon = (
    <i
      hidden={hidden}
      onClick={onClick}
      style={{
        fontSize: fontSize,
        ...style,
      }}
      className={classNames(name, className, {
        [styles.bg]: bg,
        [styles.danger]: danger,
        [styles.hover]: !!(onClick || onMenu),
      })}
    />
  );

  if (onMenu) {
    return (
      <PopupOpener
        mode="click"
        placement="bottom-start"
        content={onMenu}
        {...popupOpenerProps}
      >
        {Icon}
      </PopupOpener>
    );
  } else {
    return Icon;
  }
}
