import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { LanguageContext } from "../../context/language-context/language.context";
import { text } from "../../text";
import { sendGetRequest } from "../../util/send-get-request";
import styles from "./result.styles.module.css";

interface ITest {
  id: number;
  name: string;
  email: string;
  time: number;
  modelResults: [number, number, number, number, number];
  data: string;
  timeSpent: number;
}

export default function ResultComponent() {
  const { lang } = useContext(LanguageContext);
  const { id } = useParams();

  const [test, setTest] = React.useState<ITest>();

  React.useEffect(() => {
    const getData = async () => {
      const { payload }: { payload: ITest[] } = await sendGetRequest("/get/model/results").then(
        (res) => res?.json()
      );
      setTest(payload.find((t) => t.id === Number(id)));
    };
    getData();
  }, []);

  if (!test) return <></>;

  let timeSpent = new Date(test.timeSpent);
  return (
    <div className={styles.contianer}>
      <div className={styles.title}>
        {text.the_result_of_the_test[lang]} "{test?.name}"
      </div>
      <div className={styles.description}>
        <b>{text.test_time_started[lang]}</b>
        <span style={{ marginRight: "10px" }}>{new Date(test.time).toLocaleString()}</span>
        <b>{text.test_time_spent[lang]}</b>
        {(timeSpent.getUTCHours() < 10 ? "0" : "") + timeSpent.getUTCHours()}:
        {(timeSpent.getMinutes() < 10 ? "0" : "") + timeSpent.getUTCMinutes()}:
        {(timeSpent.getUTCSeconds() < 10 ? "0" : "") + timeSpent.getUTCSeconds()}
      </div>

      <div className={styles.result}>
        <div className={styles.result_title}>
          {text.matching_clusters_and_matching_levels[lang]}
        </div>
        <div className={styles.result_grid}>
          <div className={styles.result_key}>{text.natural_and_technical[lang]}</div>
          <div className={styles.result_value}>
            <div
              className={styles.result_thumb}
              style={{ width: test.modelResults[0] * 100 + "%" }}
            />
          </div>
          <div className={styles.result_key}>{text.economy_and_geography[lang]}</div>
          <div className={styles.result_value}>
            <div
              className={styles.result_thumb}
              style={{ width: test.modelResults[1] * 100 + "%" }}
            />
          </div>
          <div className={styles.result_key}>{text.philology_pedagogy_and_art[lang]}</div>
          <div className={styles.result_value}>
            <div
              className={styles.result_thumb}
              style={{ width: test.modelResults[2] * 100 + "%" }}
            />
          </div>
          <div className={styles.result_key}>{text.sociology_and_law[lang]}</div>
          <div className={styles.result_value}>
            <div
              className={styles.result_thumb}
              style={{ width: test.modelResults[3] * 100 + "%" }}
            />
          </div>
          <div className={styles.result_key}>{text.medicine_biology_and_sports[lang]}</div>
          <div className={styles.result_value}>
            <div
              className={styles.result_thumb}
              style={{ width: test.modelResults[4] * 100 + "%" }}
            />
          </div>
        </div>
      </div>

      <Link to={`/jobs/${id}`} className={styles.next_button}>
        {text.review_of_suitable_occupations[lang]}
      </Link>
    </div>
  );
}
