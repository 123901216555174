import { Button } from "../../component/button/button.component";
import { Icon } from "../../component/icon/icon.component";
import { Input } from "../../component/input/input.component";
import { LoadingSpinner } from "../../component/loading-spinner/loading-spinner.component";
import { useContext, useState } from "react";
import { LanguageContext } from "../../context/language-context/language.context";
import { text } from "../../text";
import styles from "./forgot-password.styles.module.css";
import { sendPostRequest } from "../../util/send-post-request";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function ForgotPasswordComponent() {
  const { lang } = useContext(LanguageContext);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [reset, setReset] = useState(false);
  const [error, setError] = useState("");
  const [typePassword, setTypePassword] = useState({
    newPassword: true,
    reNewPassword: true,
  });

  const [passwrodData, setPasswordData] = useState({
    newPassword: "",
    reNewPasswrod: "",
  });

  const navigate = useNavigate();

  async function hendleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const res = await sendPostRequest("/user/has", { email: email });
    if (res) {
      const json = await res.json();
      if (json.status === 200) {
        setHasEmail(true);
        const resSendMessageToEmail = await sendPostRequest("/verify/email", {
          email: email,
        });
        if (resSendMessageToEmail) {
          const sendMessageEmailJson = await resSendMessageToEmail.json();
          if (sendMessageEmailJson.status === 200) {
            setHasEmail(true);
            return;
          }
        }
      } else if (json.status === 404) {
        toast.error("Can't find email", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    setLoading(false);
  }
  return (
    <div className={styles.container}>
      {!hasEmail ? (
        <form className={styles.modal_container} onSubmit={hendleSubmit}>
          <h3>{text.password_recovery[lang]}</h3>
          <label htmlFor="email">
            <span>{text.email[lang]}</span>
            <Input
              mode="primary"
              type="email"
              id="email"
              autoFocus={true}
              icon="fa-solid fa-x"
              onIconClick={() => {
                setEmail("");
              }}
              onValueChange={(e) => {
                setEmail(e);
              }}
              value={email}
              required
            />
          </label>

          <Button mode="primary" disabled={loading}>
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {text.password_recovery[lang]}{" "}
                <Icon name="fa-solid fa-arrow-right" size="small" />
              </>
            )}
          </Button>
        </form>
      ) : !reset ? (
        <div className={styles.modal_container}>
          <h3>{text.confirm_email[lang]}</h3>
          <Input
            mode="primary"
            type="text"
            placeholder="code"
            onValueChange={(e) => {
              const regex = /^[0-9]*$/;
              const newInputValue = e;
              if (
                newInputValue === "" ||
                (regex.test(newInputValue) && newInputValue.length < 5)
              ) {
                setCode(
                  newInputValue !== ""
                    ? String(Number(newInputValue))
                    : newInputValue
                );
              }
            }}
            value={code}
          />
          <p
            style={{
              color: "#8592aa",
            }}
          >
            {text.we_have_sent[lang]}
          </p>
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true);
              const res = await sendPostRequest("/user/check-code", {
                email: email,
                code: code,
              });

              if (res) {
                const json = await res.json();
                if (json.status === 200) {
                  setReset(true);
                } else if (json.status === 400) {
                  toast.error(`${text.your_code_is_wrong[lang]}`, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
              setLoading(false);
            }}
          >
            {loading ? <LoadingSpinner /> : text.confirm[lang]}
          </Button>
        </div>
      ) : (
        <div className={styles.modal_container}>
          <h3>{text.password_recovery[lang]}</h3>
          <label htmlFor="newpassword">
            <span>{text.new_passwrod[lang]}</span>
            <Input
              mode="primary"
              id="newpassword"
              type={typePassword.newPassword ? "password" : "text"}
              icon={
                typePassword.newPassword
                  ? "fa-regular fa-eye"
                  : "fa-regular fa-eye-slash"
              }
              onIconClick={() => {
                setTypePassword({
                  ...typePassword,
                  newPassword: !typePassword.newPassword,
                });
              }}
              onValueChange={(e) => {
                setPasswordData({
                  ...passwrodData,
                  newPassword: e,
                });
              }}
              value={passwrodData.newPassword}
              required
            />
          </label>

          <label htmlFor="repaetnewpassword">
            <span>{text.repeat_new_password[lang]}</span>
            <Input
              mode="primary"
              id="repaetnewpassword"
              type={typePassword.reNewPassword ? "password" : "text"}
              icon={
                typePassword.reNewPassword
                  ? "fa-regular fa-eye"
                  : "fa-regular fa-eye-slash"
              }
              onIconClick={() => {
                setTypePassword({
                  ...typePassword,
                  reNewPassword: !typePassword.reNewPassword,
                });
              }}
              onValueChange={(e) => {
                setPasswordData({
                  ...passwrodData,
                  reNewPasswrod: e,
                });
              }}
              value={passwrodData.reNewPasswrod}
              required
            />
          </label>
          <p
            style={{
              color: "red",
            }}
          >
            {error}
          </p>
          <Button
            mode="primary"
            disabled={loading}
            onClick={async () => {
              if (passwrodData.newPassword !== passwrodData.reNewPasswrod) {
                setError(text.passwords_do_not_match[lang]);
                return;
              }
              const res = await sendPostRequest("/user/restore", {
                email: email,
                newPassword: passwrodData.newPassword,
                code: code,
              });
              if (res) {
                const json = await res.json();
                if (json.status === 200) {
                  toast.success("Password reset successfully", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  navigate("/login");
                } else if (json.status === 200) {
                  toast.error("You code is incorect", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
            }}
          >
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {text.recovery[lang]}{" "}
                <Icon name="fa-solid fa-arrow-right" size="small" />
              </>
            )}
          </Button>
        </div>
      )}
    </div>
  );
}
