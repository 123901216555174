import styles from "./choose-emoji.styles.module.css";
import classNames from "classnames";
import { Icon } from "../icon/icon.component";

export default function ChooseEmojiComponent({
  item,
  text,
  number,
  setNumber,
}: {
  item: number;
  text: string;
  number: number;
  setNumber: (value: number) => void;
}) {
  return (
    <div className={styles.contianer}>
      <div className={styles.quastion_container}>
        <div>
          <p>{item}.</p>
        </div>
        <div>{text}</div>
      </div>

      <div className={styles.choose_all_container}>
        <div
          onClick={() => {
            setNumber(1);
          }}
          className={classNames({
            [styles.chosse_container]: true,
            [styles.isActive]: number === 1,
          })}
        >
          <Icon name="fa-regular fa-face-frown" size="small" />
        </div>

        <div
          onClick={() => {
            setNumber(2);
          }}
          className={classNames({
            [styles.chosse_container]: true,
            [styles.isActive]: number === 2,
          })}
          style={
            number === 2
              ? {
                  backgroundColor: "orange",
                }
              : {}
          }
        >
          <Icon name="fa-regular fa-face-meh" size="small" />
        </div>
        <div
          onClick={() => {
            setNumber(3);
          }}
          className={classNames({
            [styles.chosse_container]: true,
            [styles.isActive]: number === 3,
          })}
          style={
            number === 3
              ? {
                  backgroundColor: "yellow",
                }
              : {}
          }
        >
          <Icon name="fa-regular fa-face-smile" size="small" />
        </div>
        <div
          onClick={() => {
            setNumber(4);
          }}
          className={classNames({
            [styles.chosse_container]: true,
            [styles.isActive]: number === 4,
          })}
          style={
            number === 4
              ? {
                  backgroundColor: "#c7ff73",
                }
              : {}
          }
        >
          <Icon name="fa-regular fa-face-smile-beam" size="small" />
        </div>
        <div
          onClick={() => {
            setNumber(5);
          }}
          className={classNames({
            [styles.chosse_container]: true,
            [styles.isActive]: number === 5,
          })}
          style={
            number === 5
              ? {
                  backgroundColor: "#99ff00",
                }
              : {}
          }
        >
          <Icon name="fa-regular fa-face-grin-hearts" size="small" />
        </div>
      </div>
    </div>
  );
}
