import React from "react";
import { useParams } from "react-router-dom";
import { sendGetRequest } from "../../util/send-get-request";
import styles from "./speciality.styles.module.css";

interface IData {
  index: number;
  id: number;
  education: string;
  grade: number;
  specialization_group: number;
  cluster_name: string;
  tag_by_cluster: string;
  specialty_description: string;
  tag_by_specialty: string;
  specialty_code: number;
  name_of_specialty: string;
  form_of_education: string;
  type_of_education: string;
  tuition_fee_in_somoni: string;
  language_of_education: string;
  name_of_educational_institution: string;
}

export default function SpecialityComponent() {
  const { id } = useParams();

  const [data, setData] = React.useState<IData[]>();

  const [pending, setPending] = React.useState(false);

  React.useEffect(() => {
    setPending(true);
    sendGetRequest(`/mmt/get?id=${id}`)
      .then((res) => res?.json())
      .then((res) => {
        sendGetRequest(`/mmt/get?name=${res?.payload[0].name_of_specialty}`)
          .then((res) => res?.json())
          .then((res) => {
            setData(res.payload);
            setPending(false);
          });
      });
  }, []);

  if (!data) return <></>;
  return (
    <div className={styles.contianer}>
      <div className={styles.header}>
        <div className={styles.title}>{data[0].name_of_specialty}</div>
        <div className={styles.description}>
          <div className={styles.description_item}>
            <div className={styles.key}>Номи кластер:</div>
            <div className={styles.value}>{data[0].cluster_name}</div>
          </div>
        </div>
      </div>
      <div className={styles.data}>
        {!pending &&
          data.map((item) => (
            <div className={styles.data_card} key={item.id}>
              <div className={styles.card_item}>
                <div className={styles.key}>Номи муассисаи таълимӣ:</div>
                <div className={styles.header_value}>{item.name_of_educational_institution}</div>
              </div>
              <div className={styles.card_item}>
                <div className={styles.key}>Маблағи таҳсил (бо сомонӣ)</div>
                <div className={styles.value}>{item.tuition_fee_in_somoni ?? "ройгон"}</div>
              </div>
              <div className={styles.card_item}>
                <div className={styles.key}>Шакли таҳсил</div>
                <div className={styles.value}>{item.form_of_education}</div>
              </div>
              <div className={styles.card_item}>
                <div className={styles.key}>Таҳсилот</div>
                <div className={styles.value}>{item.education}</div>
              </div>
              <div className={styles.card_item}>
                <div className={styles.key}>Рамзи ихтисос</div>
                <div className={styles.value}>{item.specialty_code}</div>
              </div>
              <div className={styles.card_item}>
                <div className={styles.key}>Забони таҳсил</div>
                <div className={styles.value}>{item.language_of_education}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
