import { useContext, useState } from "react";
import styles from "./home.styles.module.css";
import { text } from "../../text.tsx";
import { LanguageContext } from "../../context/language-context/language.context.tsx";
import { Header } from "../../component/header/header.component.tsx";
import VideoComponent from "../../component/video/video.component.tsx";
import video from "../../assets/header-page/header.mp4";
import { Button } from "../../component/button/button.component.tsx";
import { Icon } from "../../component/icon/icon.component.tsx";
import Card from "../../component/card/card.component.tsx";
import ForCardComponent from "../../component/for-card/for-card.component.tsx";
import { Input } from "../../component/input/input.component.tsx";
import emailImg from "../../assets/email/sub-img.png";
import { NavLink, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth-context/auth.context.tsx";
export default function HomeComponent() {
  const { lang } = useContext(LanguageContext);
  const [onEmailChange, setEmailChange] = useState("");
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  return (
    <div className={styles.container}>
      <div className={styles.header_container}>
        <div>
          <Header
            title={`${text.build_your_future[lang]}`}
            description={`${text.zypl_career[lang]}`}
            fontSize={"36px"}
            className={styles.header_container_text}
          />
          <NavLink to={authData ? "/test" : "/login"}>
            <Button
              mode={"primary"}
              style={{
                borderRadius: "20px",
                fontWeight: "700",
                padding: "20px",
              }}
            >
              {text.start[lang]} <Icon name={"fa-solid fa-arrow-right"} size={"1.6"} />
            </Button>
          </NavLink>
        </div>

        <div className={styles.right_container}>
          <VideoComponent src={video} />
        </div>
      </div>
      <div className={styles.tool_container}>
        <Header
          title={`${text.tools_for_inspiration_and_motivation[lang]}`}
          description={`${text.use_these_resources[lang]}`}
          fontSize={"36px"}
          className={styles.tool_header}
        />

        <div className={styles.card_container}>
          <Card
            component={
              <ForCardComponent
                headerText={text.database_of_test_center_specialties[lang]}
                description={text.through_this_database[lang]}
                src="/images/1.jpg"
              />
            }
          />
          <Card
            component={
              <ForCardComponent
                headerText={text.database_of_test_center_specialties[lang]}
                description={text.through_this_database[lang]}
                src="/images/1.jpg"
              />
            }
          />
          <Card
            component={
              <ForCardComponent
                headerText={text.database_of_test_center_specialties[lang]}
                description={text.through_this_database[lang]}
                src="/images/1.jpg"
              />
            }
          />
        </div>
      </div>

      <div className={styles.professional_advice_container}>
        <div className={styles.professional_advice_left_container}>
          <Header title={text.professional_advice[lang]} fontSize={"36px"} />
          <Header
            title={text.get_career_information_directly_to_your_email[lang]}
            description={text.be_one_step_ahead[lang]}
            fontSize={"20px"}
          />
          <div className={styles.email_container}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <label htmlFor={"1"}>
                <span>{text.email[lang]}</span>
                <Input
                  id={"1"}
                  mode={"primary"}
                  style={{ width: "400px" }}
                  icon={"fa-solid fa-x"}
                  onIconClick={() => {
                    setEmailChange("");
                  }}
                  onChange={(e) => {
                    setEmailChange(e.target.value);
                  }}
                  type={"email"}
                  required
                  value={onEmailChange}
                />
              </label>
              <Button
                mode={"primary"}
                style={{
                  padding: "20px",
                  fontWeight: "700",
                }}
              >
                {text.subscribe[lang]} <Icon name={"fa-solid fa-arrow-right"} size={"small"} />
              </Button>
            </form>
          </div>
        </div>
        <div className={styles.professional_advice_right_container}>
          <img src={emailImg} alt={"emailImg"} />
        </div>
      </div>
      <div className={styles.container_build_profile}>
        <Header
          title={`${text.start_building_your_professional_profile[lang]}`}
          fontSize="32px"
          description={`${text.go_through_our[lang]}`}
          className={styles.header_build_profile}
        />

        <div
          style={{
            width: "80%",
          }}
        >
          <Button
            mode="primary"
            className={styles.button_build_profile}
            onClick={() => {
              if (authData) {
                navigate("http://zyplcareer.com/c/quiz_13/");
              } else {
                navigate("/login");
              }
            }}
          >
            {text.pass_the_test[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
          </Button>
        </div>
      </div>
    </div>
  );
}
