import { decryptString } from "./decrypt-encrypt-string.ts";
import { AppConfig } from "../app/app.config.ts";

export async function sendGetRequest(url: string) {
  try {
    const userGetLocalStorage = localStorage.getItem("auth");
    const decrypt = userGetLocalStorage
      ? JSON.parse(decryptString(userGetLocalStorage, AppConfig.jwtSecret))
      : undefined;
    const token = decrypt && decrypt.token;
    return await fetch(AppConfig.host + url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    console.error(error);
  }
}
