import { useContext, useEffect } from "react";
import styles from "./sidebar.styles.module.css";
import classNames from "classnames";
import { Icon } from "../icon/icon.component";
import { SidebarOptions } from "./sidebar.context";
import headerLogo from "../../assets/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { LanguageContext } from "../../context/language-context/language.context.tsx";
import { text } from "../../text.tsx";
import { AuthContext } from "../../context/auth-context/auth.context.tsx";

export function Sidebar(props: { isOpened: boolean; close: () => void; options: SidebarOptions }) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { lang } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { authData } = useContext(AuthContext);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    // todo: use useSyncExternalStore instead
    if (!props.isOpened) return;
    if (!props.options.closeOnEsc) return;

    const handleKeydown = (e: any) => {
      if (e.keyCode === 27) {
        props.close();
      }
    };
    window.addEventListener("keydown", handleKeydown);
    return () => window.removeEventListener("keydown", handleKeydown);
  }, [props.isOpened, props.options.closeOnEsc]);

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  function onOverlayClick(event: any) {
    if (!props.options.closeOnOutsideClick) return;

    if (event.target?.id === "overlay") {
      props.close();
    }
  }

  // ---------------------------------------------------------------------------
  return (
    <div
      id="overlay"
      className={classNames({
        [styles.overlay]: true,
        [styles.opened]: props.isOpened,
        [styles.closed]: !props.isOpened,
      })}
      onClick={(event) => onOverlayClick(event)}
    >
      <div
        className={classNames({
          [styles.sidebar]: true,
          [styles.opened]: props.isOpened,
          [styles.closed]: !props.isOpened,
        })}
        style={{ width: `${props.options.width}em` || "30em" }}
      >
        <div className={styles.crossButtonContainer}>
          <Icon
            className={styles.crossButton}
            name="fa-solid fa-arrow-left"
            onClick={() => props.close()}
          />
        </div>
        <div className={styles.contentContainer}>{props.options.component}</div>
        <div
          className={styles.header}
          onClick={() => {
            navigate("/");
            props.close();
          }}
        >
          <img src={headerLogo} alt={"header"} width={"100px"} />
        </div>

        <div className={styles.menu}>
          <ul>
            {/* <li>
              <NavLink
                to={"/student"}
                onClick={() => {
                  props.close();
                }}
              >
                {text.student[lang]}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/education"}
                onClick={() => {
                  props.close();
                }}
              >
                {text.in_educational_institutions[lang]}
              </NavLink>
            </li> */}
            {!authData && (
              <li>
                <NavLink
                  to={"/sign-up"}
                  onClick={() => {
                    props.close();
                  }}
                >
                  {text.registration[lang]}
                </NavLink>
              </li>
            )}
            {!authData && (
              <li>
                <NavLink
                  to={"/login"}
                  onClick={() => {
                    props.close();
                  }}
                >
                  {text.log_in_to_the_system[lang]}
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}
