import styles from "./menu.styles.module.css";
import { NavLink } from "react-router-dom";
import { text } from "../../text.tsx";
import { useContext } from "react";
import { LanguageContext } from "../../context/language-context/language.context.tsx";
import { AuthContext } from "../../context/auth-context/auth.context.tsx";
export default function MenuComponent() {
  const { lang } = useContext(LanguageContext);
  const { authData } = useContext(AuthContext);
  return (
    <ul className={styles.container}>
      {/* <li>
        <NavLink to={"/student"}>{text.student[lang]}</NavLink>
      </li>
      <li>
        <NavLink to={"/education"}>
          {text.in_educational_institutions[lang]}
        </NavLink>
      </li> */}
      {!authData && (
        <li>
          <NavLink to={"/sign-up"}>{text.registration[lang]}</NavLink>
        </li>
      )}
      {!authData && (
        <li>
          <NavLink to={"/login"}>{text.log_in_to_the_system[lang]}</NavLink>
        </li>
      )}
    </ul>
  );
}
