import styles from "./for-component.styles.module.css";
import { Header } from "../header/header.component.tsx";
function ForCardComponent(props: {
  src: string;
  headerText: string;
  description: string;
}) {
  return (
    <div className={styles.container}>
      <img src={props.src} alt={"img"} width={"300px"} />
      <Header
        title={props.headerText}
        description={props.description}
        fontSize={"18px"}
      />
    </div>
  );
}

export default ForCardComponent;
