import { ReactNode, useCallback, useState } from "react"
import { SidebarContext, SidebarOptions } from "./sidebar.context"
import { Sidebar } from "./sidebar.component"

export function SidebarProvider(props: { children: ReactNode }) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [isRendered, setRendered] = useState<boolean>(false)
  const [isOpened, setOpened] = useState<boolean>(false)
  const [options, setOptions] = useState<SidebarOptions>({})

  // ---------------------------------------------------------------------------
  // memo callbacks
  // ---------------------------------------------------------------------------

  const openFn = useCallback((options?: SidebarOptions) => {
    setRendered(true)
    setTimeout(() => {
      setOpened(true)
      if (options) setOptions(options)
    })
  }, [])

  const closeFn = useCallback((reset = false) => {
    setOpened(false)
    if (reset) {
      setOptions({})
    }
    if (options.onClose) {
      options.onClose()
    }
    setTimeout(() => {
      setRendered(false)
    }, 300)
  }, [])

  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [value] = useState({
    open: openFn,
    close: closeFn,
    setOptions,
  })

  // ---------------------------------------------------------------------------
  return (
    <SidebarContext.Provider value={value}>
      {isRendered && (
        <Sidebar isOpened={isOpened} close={closeFn} options={options} />
      )}
      {props.children}
    </SidebarContext.Provider>
  )
}
