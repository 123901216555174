import {
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Modifier, usePopper } from "react-popper";
import { ModifierPhases } from "@popperjs/core";
import { memoComponent } from "../../util/memo-component.util";
import { PopupOptions } from "./popup.types";
import ReactDOM from "react-dom";
import classNames from "classnames";
import styles from "./popup.styles.module.css";

export const Popup = memoComponent(
  "Popup",
  function ({
    children,
    options,
    onOptionsChange,
    onClose,
  }: {
    children: ReactNode;
    options: PopupOptions;
    onOptionsChange: (fn: (options: PopupOptions) => any) => any;
    onClose?: () => any;
  }) {
    // ---------------------------------------------------------------------------
    // variables
    // ---------------------------------------------------------------------------

    const [ref, setRef] = useState<any>(null);
    const virtualContainer = useRef<any>(null);

    const popperModifiers: Modifier<any>[] = useMemo(
      () => [
        {
          name: "offset",
          options: {
            offset: options.offset,
          },
        },
        {
          // todo: this modifier can be customizable via new option if needed
          name: "sameWidth",
          enabled: true,
          phase: "beforeWrite" as ModifierPhases,
          requires: ["computeStyles"],
          fn({ state }) {
            state.styles.popper.minWidth = `${state.rects.reference.width}px`;
          },
          effect({ state }) {
            state.elements.popper.style.minWidth = `${
              (state.elements.reference as HTMLElement).offsetWidth
            }px`;
          },
        },
      ],
      [options.offset],
    );

    const { styles: popperStyles, attributes } = usePopper(
      virtualContainer.current,
      ref,
      {
        modifiers: popperModifiers,
        placement: options.placement,
      },
    );

    // ---------------------------------------------------------------------------
    // effects
    // ---------------------------------------------------------------------------

    useEffect(() => {
      setupVirtualContainer();
    }, [options.position]);

    useEffect(() => {
      if (!options.position) return;
      if (options.closeOnOutsideClick === false) return;

      function handleClickOutside(event: any) {
        if (options.position && (options.position as RefObject<any>).current) {
          if (
            ref &&
            !ref.contains(event.target) &&
            !(options.position as RefObject<any>).current.contains(event.target)
          ) {
            close();
          }
        } else {
          if (ref && !ref.contains(event.target)) {
            close();
          }
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, options.position, options.closeOnOutsideClick]);

    useEffect(() => {
      // todo: use useSyncExternalStore instead
      if (!options.position) return;

      const handleKeydown = (e: any) => {
        if (options.closeOnEsc === false) return;
        if (e.keyCode === 27) {
          close();
        }
      };

      window.addEventListener("keydown", handleKeydown);
      return () => window.removeEventListener("keydown", handleKeydown);
    }, [options.position, options.closeOnEsc]);

    // ---------------------------------------------------------------------------
    // functions
    // ---------------------------------------------------------------------------

    function setupVirtualContainer() {
      if (
        options.position &&
        (options.position as { x: number; y: number }).x &&
        (options.position as { x: number; y: number }).y
      ) {
        virtualContainer.current = {
          getBoundingClientRect() {
            return {
              width: 1,
              height: 1,
              bottom: 0,
              right: 0,
              left: options.position
                ? (options.position as { x: number; y: number }).x
                : 0,
              top:
                options.position &&
                (options.position as { x: number; y: number }).y < 250
                  ? (options.position as { x: number; y: number }).y - 100
                  : (options.position as { x: number; y: number }).y < 440
                  ? (options.position as { x: number; y: number }).y - 295
                  : (options.position as { x: number; y: number }).y > 457
                  ? (options.position as { x: number; y: number }).y - 380
                  : 0,
            };
          },
        };
      } else if (options.position) {
        // we assume position is a ref here
        virtualContainer.current = (options.position as RefObject<any>).current;
      } else {
        virtualContainer.current = null;
      }
    }

    function close() {
      onOptionsChange((options) => ({ ...options, position: undefined }));
      onClose?.();
    }

    function onClick() {
      if (options.closeOnInteract) {
        console.log("closing on interact");
        close();
      }
    }

    // ---------------------------------------------------------------------------
    if (!options.position) return null;
    if (!children) return null;

    return ReactDOM.createPortal(
      <div
        id="overlay"
        className={classNames({
          [styles.overlay]: true,
        })}

        // onClick={(event) => onOverlayClick(event)}
      >
        <div
          ref={setRef}
          className={classNames({
            [styles.popup]: true,
          })}
          style={{
            zIndex: 999,
            ...popperStyles.popper,
            ...options.style,
            backgroundColor: "#ffffff27",
            backdropFilter: "blur(10px)",
          }}
          {...attributes.popper}
          onClick={onClick}
        >
          {children}
        </div>
      </div>,
      document.getElementById("root-popup")!,
    );
  },
);
