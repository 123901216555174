import React from "react";
import { Link, useParams } from "react-router-dom";
import classNames from "classnames";
import { sendGetRequest } from "../../util/send-get-request";
import { Icon } from "../../component/icon/icon.component";
import styles from "./jobs.styles.module.css";

interface ITest {
  id: number;
  name: string;
  email: string;
  time: number;
  modelResults: [number, number, number, number, number];
  data: string;
  timeSpent: number;
}

interface IData {
  entity_index: number;
  entity_id: number;
  entity_education: string;
  entity_grade: number;
  entity_specialization_group: number;
  entity_cluster_name: string;
  entity_tag_by_cluster: string;
  entity_specialty_description: string;
  entity_tag_by_specialty: string;
  entity_specialty_code: number;
  entity_name_of_specialty: string;
  entity_form_of_education: string;
  entity_type_of_education: string;
  entity_tuition_fee_in_somoni: string;
  entity_language_of_education: string;
  entity_name_of_educational_institution: string;
}

let deboune: any;
export default function JobsComponent() {
  const { id } = useParams();

  const [test, setTest] = React.useState<ITest>();
  const [data, setData] = React.useState<IData[]>();

  const [maxDataLength, setMaxDataLength] = React.useState<number>(Number.MIN_VALUE);
  const [from, setFrom] = React.useState<number>(0);
  const [to, setTo] = React.useState<number>(9);
  const [pending, setPending] = React.useState(false);

  React.useEffect(() => {
    if (!test) return;
    setPending(true);
    const getData = async () => {
      const { message: messageJobs, payload: payloadJobs }: { message: number; payload: IData[] } =
        await sendGetRequest(
          `/get/specialty?from=${from}&to=${to + 1}&modelResult=${JSON.stringify(
            test?.modelResults
          )}`
        ).then((res) => res?.json());
      setData(payloadJobs);
      setMaxDataLength(messageJobs);
      setPending(false);
    };
    deboune = null;
    deboune = setTimeout(() => getData(), 300);
    return () => clearTimeout(deboune);
  }, [from, to, test]);

  React.useEffect(() => {
    const getData = async () => {
      const { payload: payloadTest }: { payload: ITest[] } = await sendGetRequest(
        "/get/model/results"
      ).then((res) => res?.json());
      let findedTest = payloadTest.find((t) => t.id === Number(id));
      setTest(findedTest);
    };
    getData();
  }, []);

  if (!data) return <></>;
  return (
    <div className={styles.contianer}>
      <div className={styles.title}>Ба Шумо ин касбҳо мувофиқанд</div>
      <div className={styles.pagination_text}>
        Намиоиши {from} – {to + 1} аз {maxDataLength} натиҷа
      </div>
      <div className={styles.data}>
        {!pending && (
          <div className={styles.data_list}>
            {data.slice(0, 5).map((item) => (
              <Link
                to={`/speciality/${item.entity_id}`}
                className={styles.data_item}
                key={item.entity_id}
              >
                {item.entity_name_of_specialty}
              </Link>
            ))}
          </div>
        )}
        {!pending && (
          <div className={styles.data_list}>
            {data.slice(5, 10).map((item) => (
              <Link
                to={`/speciality/${item.entity_id}`}
                className={styles.data_item}
                key={item.entity_id}
              >
                {item.entity_name_of_specialty}
              </Link>
            ))}
          </div>
        )}
        {pending && (
          <div className={styles.data_list}>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <div className={styles.data_item_pending} key={index} />
              ))}
          </div>
        )}
        {pending && (
          <div className={styles.data_list}>
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <div className={styles.data_item_pending} key={index} />
              ))}
          </div>
        )}
      </div>
      <div className={styles.pagination}>
        <button
          className={classNames(styles.pagination_page, from === 0 && styles.disabled)}
          onClick={() => {
            setFrom(Math.max(from - 10, 0));
            setTo(Math.max(from - 1, 9));
          }}
        >
          <Icon name="fa-solid fa-arrow-left" size="small" /> Ба қафо
        </button>
        <button
          className={classNames(
            styles.pagination_page,
            to === maxDataLength - 1 && styles.disabled
          )}
          onClick={() => {
            setFrom(Math.min(from + 10, maxDataLength - (maxDataLength % 10)));
            setTo(Math.min(from + 19, maxDataLength - 1));
          }}
        >
          Ба пеш
          <Icon name="fa-solid fa-arrow-right" size="small" />
        </button>
      </div>
      <div className={styles.pagination_text}>
        Намиоиши {from} – {to + 1} аз {maxDataLength} натиҷа
      </div>
    </div>
  );
}
