import React, { useContext, useState } from "react";
import styles from "./first-test.styles.module.css";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Icon } from "../../component/icon/icon.component";
import { Header } from "../../component/header/header.component";
import ChooseEmojiComponent from "../../component/choose-emoji/choose-emoji.component";
import { Button } from "../../component/button/button.component";
import { text } from "../../text";
import { LanguageContext } from "../../context/language-context/language.context";
import { decryptString } from "../../util/decrypt-encrypt-string";
import { AppConfig } from "../../app/app.config";

let startDate = 0;

export default function FirstTestComponent() {
  const [chapter, setChapter] = useState(1);
  const { lang } = useContext(LanguageContext);
  const navigate = useNavigate();

  const [firstTest, setFirstTest] = React.useState([
    {
      key: "1",
      label: text.prepare_a_budget_for_any_enterprise,
      rating: 0,
    },
    {
      key: "2",
      label: text.promotion_of_enterprisebusiness_documentation,
      rating: 0,
    },
    {
      key: "3",
      label: text.calculate_the_interest_on_the_loan,
      rating: 0,
    },
    {
      key: "4",
      label: text.installing_a_light_switch,
      rating: 0,
    },
    {
      key: "5",
      label: text.repairing_motorcycles_and_bicycles,
      rating: 0,
    },
    {
      key: "6",
      label: text.singing_or_playing_an_instrument_in_a_band_or_composing_music,
      rating: 0,
    },
    {
      key: "7",
      label: text.explore_your_favorite_music_genre,
      rating: 0,
    },
    {
      key: "8",
      label: text.compare_the_prices_of_similar_goods_and_decide_which_one_is_the_best,
      rating: 0,
    },
    {
      key: "9",
      label: text.teaching_children_with_special_educational_needs,
      rating: 0,
    },
    {
      key: "10",
      label: text.repair_of_water_pipes,
      rating: 0,
    },
    {
      key: "11",
      label: text.working_with_youth_in_extracurricular_activities,
      rating: 0,
    },
    {
      key: "12",
      label: text.arranging_decorating_trees_or_bushes,
      rating: 0,
    },
    {
      key: "13",
      label: text.find_out_why_there_are_big_waves_in_the_ocean,
      rating: 0,
    },
    {
      key: "14",
      label: text.design_of_clothes,
      rating: 0,
    },
    {
      key: "15",
      label: text.motivating_people_to_get_things_done,
      rating: 0,
    },
    {
      key: "16",
      label: text.study_the_effects_of_radiation,
      rating: 0,
    },
    {
      key: "17",
      label: text.volunteering_in_public_organizations_and_delivering_food_to_families_with_needs,
      rating: 0,
    },
    {
      key: "18",
      label: text.write_a_poem_about_someone_or_something,
      rating: 0,
    },
    {
      key: "19",
      label: text.repairing_a_broken_car,
      rating: 0,
    },
    {
      key: "20",
      label: text.speech_to_motivate_people,
      rating: 0,
    },
    {
      key: "21",
      label: text.participation_in_the_meeting_of_scientists,
      rating: 0,
    },
    {
      key: "22",
      label: text.create_a_schedule_of_business_transactions,
      rating: 0,
    },
    {
      key: "23",
      label: text.being_the_head_of_an_organization,
      rating: 0,
    },
    {
      key: "24",
      label: text.working_with_adults_from_other_families,
      rating: 0,
    },
    {
      key: "25",
      label: text.patient_support_in_the_hospital,
      rating: 0,
    },
    {
      key: "26",
      label: text.serve_as_a_club_or_organization_leader,
      rating: 0,
    },
    {
      key: "27",
      label: text.convince_others_to_invest_in_your_company,
      rating: 0,
    },
    {
      key: "28",
      label: text.installing_new_shelvesfurniture_in_a_room,
      rating: 0,
    },
    {
      key: "29",
      label: text.repairing_damaged_equipment,
      rating: 0,
    },
    {
      key: "30",
      label: text.get_acquainted_with_the_latest_achievements_in_science_and_technology,
      rating: 0,
    },
    {
      key: "31",
      label: text.scene_design_for_a_scenedramaor_video_shoot,
      rating: 0,
    },
  ]);
  const [secondTest, setSecondTest] = React.useState([
    {
      key: "1",
      cards: ["chemistry.png", "electronics.png"],
      value: ["Chemistry", "Electronics"],
      labels: [text.chemistry, text.electronics],
      selected: 0,
    },
    {
      key: "2",
      cards: ["photography.png", "botany.png"],
      value: ["Photography", "Botany"],
      labels: [text.photography, text.botany],
      selected: 0,
    },
    {
      key: "3",
      cards: ["tips_for_work.png", "being_a_shop_owner.png"],
      value: ["Advice on work", "Owning a store"],
      labels: [text.tips_for_work, text.being_a_shop_owner],
      selected: 0,
    },
    {
      key: "4",
      cards: ["company_management.png", "helping_families_in_need.png"],
      value: ["Helping families in need", "Company management"],
      labels: [text.company_management, text.helping_families_in_need],
      selected: 0,
    },
    {
      key: "5",
      cards: ["physical_education.png", "doctor.png"],
      value: ["Physical training", "Doktor"],
      labels: [text.physical_education, text.doctor],
      selected: 0,
    },
    {
      key: "6",
      cards: ["music.png", "work_with_wood.png"],
      value: ["Music", "Working with wood"],
      labels: [text.music, text.work_with_wood],
      selected: 0,
    },
    {
      key: "7",
      cards: ["economic_sciences.png", "physics.png"],
      value: ["Economic sciences", "Physics"],
      labels: [text.economic_sciences, text.physics],
      selected: 0,
    },
    {
      key: "8",
      cards: ["education.png", "art.png"],
      value: ["Education", "Art"],
      labels: [text.education, text.art],
      selected: 0,
    },
    {
      key: "9",
      cards: ["dealing_with_law_and_order.png", "artist_folk_crafts.png"],
      value: ["Law", "Artist / Folk Crafts"],
      labels: [text.dealing_with_law_and_order, text.artist_folk_crafts],
      selected: 0,
    },
    {
      key: "10",
      cards: ["baby_care.png", "work_with_electronics.png"],
      value: ["Child care", "Stand electronics"],
      labels: [text.baby_care, text.work_with_electronics],
      selected: 0,
    },
    {
      key: "11",
      cards: ["land_surveying_and_land_development.png", "be_in_a_music_band.png"],
      value: ["Playing in a group, being a member of a music team", "Landscaping"],
      labels: [text.land_surveying_and_land_development, text.be_in_a_music_band],
      selected: 0,
    },
    {
      key: "12",
      cards: ["travel_agency.png", "car_repair.png"],
      value: ["Travel agent", "Mechanic"],
      labels: [text.travel_agency, text.car_repair],
      selected: 0,
    },
    {
      key: "13",
      cards: ["work_in_the_office.png", "description_and_explanation_of_pictures.png"],
      value: ["Work in the office", "Picture description"],
      labels: [text.work_in_the_office, text.description_and_explanation_of_pictures],
      selected: 0,
    },
    {
      key: "14",
      cards: ["the_forest.png", "nurse.png"],
      value: ["Forest", "The nurse of mercy"],
      labels: [text.the_forest, text.nurse],
      selected: 0,
    },
    {
      key: "15",
      cards: ["economic_analysis.png", "working_with_electricity.png"],
      value: ["Economist", "Electric"],
      labels: [text.economic_analysis, text.working_with_electricity],
      selected: 0,
    },
    {
      key: "16",
      cards: ["accounting.png", "geology.png"],
      value: ["Accounting", "Geology"],
      labels: [text.accounting, text.geology],
      selected: 0,
    },
    {
      key: "17",
      cards: ["construction.png", "economic_analysis.png"],
      value: ["Builder", "Economist"],
      labels: [text.construction, text.economic_analysis],
      selected: 0,
    },
    {
      key: "18",
      cards: ["home_loan_approval_as_a_bank_employee.png", "helping_patients_in_the_hospital.png"],
      value: ["Confirmation of a home loan (mortgage)", "Helping patients in the hospital"],
      labels: [text.home_loan_approval_as_a_bank_employee, text.helping_patients_in_the_hospital],
      selected: 0,
    },
  ]);
  const [thirdTest, setThirdTest] = React.useState({
    question:
      text.which_statement_best_describes_your_familys_financial_situation_when_you_were_14_or_15_years_old,
    list: [
      text.there_was_not_always_enough_money_in_the_family_even_for_food,
      text.we_had_money_for_food_but_buying_clothes_was_a_serious_problem_for_us,
      text.we_had_enough_money_for_food_and_clothes_but_we_had_to_save_or_borrow_money_to_buy_a_refrigerator_or_a_washing_machine,
      text.without_a_loan_we_could_easily_buy_household_appliances_but_it_was_difficult_to_buy_a_car,
      text.we_could_buy_a_car_without_any_problems_but_buying_a_new_house_was_difficult,
      text.we_had_no_financial_problems_we_could_even_buy_a_new_house_if_we_wanted_to,
    ],
    model: [
      "Money did not always reach the family, even for food",
      "We had money for food, but buying clothes was a big problem for us",
      "We had enough money for food and clothes, but to buy a refrigerator",
      "Without borrowing money, he could easily buy household appliances",
      "We could have bought a car without any problems, but a house",
      "We didn't have any financial problems, we even bought a new house, aha",
    ],
    selected: "",
  });
  const [fourthTest, setFourthTest] = React.useState({
    question:
      text.which_of_the_following_statements_best_describes_your_fathers_employmentwork_situation_when_you_were_15_years_old,
    list: [
      text.officialregistered_employee_work_for_a_stranger_but_not_your_own_family_business,
      text.informalunregistered_worker_work_for_a_stranger_but_not_for_your_family_business,
      text.working_as_an_employee_or_helper_in_your_familys_nonagricultural_businessshopoutlet,
      text.work_as_an_employee_or_assistant_on_your_family_farm,
      text.selfemployedemployed_agricultural_or_nonagricultural,
      text.unemployed_looking_for_work,
      text.doing_housework_including_childcare_taking_care_of_other_family_members,
      text.he_did_not_work_due_to_limited_physical_ability_or_illness,
      text.died,
      text.i_dont_know_refusal_no_answer,
    ],
    model: [
      "Official / Registered Employee (NOT YOUR FAMILY BUSINESS)",
      "Unofficial / unregistered employee (NOT YOUR FAMILY BUSINESS)",
      "Working as an employee or assistant in a NON-agricultural business / m",
      "Work as an employee or assistant on a farm (farm, h",
      "Entrepreneur / employee with self-account / private entrepreneur / employer (yes)",
      "Unemployed (ready to work and looking for work)",
      "Doing housework (including caring for children)",
      "Does not work due to limited physical ability or illness",
      "Dead",
      "I don't know / Rejection",
    ],
    selected: "",
  });
  const [fifthTest, setFifthTest] = React.useState({
    question: text.indicate_your_fathers_highest_level_of_education_when_you_were_15_years_old,
    list: [
      text.no_formal_education,
      text.general_primary_education_grades_14,
      text.secondary_general_education_grades_59,
      text.complete_secondary_general_education_grades_1011,
      text.primary_vocational_education_ptu_or_secondary_vocational_education_technikum,
      text.higher_education_university_conservatory_academy_or_postgraduate_doctorate,
      text.dont_know_no_answer,
    ],
    model: [
      "Without formal education",
      "General Primary Education (Grades 1-4)",
      "Incomplete general secondary education (Grades 5-9)",
      "Complete general secondary education (Grades 10-11)",
      "Primary vocational education (VET) or secondary vocational education (technical school)",
      "Higher Education (University, Conservatory, Academy) or Postgraduate, d",
      "I don't know / Rejection",
    ],
    selected: "",
  });
  const [sixthTest, setSixthTest] = React.useState({
    question:
      text.which_of_the_following_best_describes_your_mothers_employment_status_when_you_were_15_years_old,
    list: [
      text.informalunregistered_worker_work_for_a_stranger_but_not_for_ones_own_family,
      text.work_as_an_employee_or_helper_in_your_familys_farm_farm_animal_husbandry,
      text.selfemployedselfemployedemployed_agricultural_or_nonagricultural,
      text.unemployed_available_for_work_and_looking_for_work,
      text.doing_housework_including_childcare_taking_care_of_other_family_members,
      text.she_did_not_work_due_to_his_limited_physical_capacity_and_illness,
      text.pensioner,
      text.died,
      text.dont_know_no_answer,
    ],
    model: [
      "Unofficial / unregistered employee (NOT YOUR FAMILY BUSINESS)",
      "Working as an employee or assistant in a NON-agricultural business / m",
      "Entrepreneur / employee with self-account / private entrepreneur / employer (yes)",
      "Unemployed (ready to work and looking for work)",
      "Doing housework (including caring for children)",
      "Does not work due to limited physical ability or illness",
      "Retired",
      "Dead",
      "I don' t know / Rejection",
    ],
    selected: "",
  });
  const [seventhTest, setSeventhTest] = React.useState({
    question: text.indicate_your_mothers_highest_level_of_education_when_you_were_15_years_old,
    list: [
      text.no_formal_education,
      text.general_primary_education_grades_14,
      text.secondary_general_education_grades_59,
      text.complete_secondary_general_education_grades_1011,
      text.primary_vocational_education_ptu_or_secondary_vocational_education_technikum,
      text.higher_education_university_conservatory_academy_or_postgraduate_doctorate,
      text.dont_know_no_answer,
    ],
    model: [
      "Without formal education",
      "General primary education (grades 1-4)",
      "Incomplete general secondary education (grades 5-9)",
      "Full secondary education (grades 10-11)",
      "Primary vocational education (vocational school) or secondary vocational (Technical school)",
      "Higher education (University, Conservatory, Academy) or Postgraduate studies, etc",
      "don't know / Denial",
    ],
    selected: "",
  });

  const checkChapter1 = () => !firstTest.find((f) => f.rating === 0);

  const checkChapter2 = () => !secondTest.find((s) => s.selected === 0);

  const checkChapter3 = () => thirdTest.selected !== "";

  const checkChapter4 = () => fourthTest.selected !== "";

  const checkChapter5 = () => fifthTest.selected !== "";

  const checkChapter6 = () => sixthTest.selected !== "";

  const checkChapter7 = () => seventhTest.selected !== "";

  const handleSubmit = async () => {
    let data = {
      name: text.a_test_for_choosing_a_professioncluster_using_artificial_intelligence[lang],
      first: firstTest.map((f) => f.rating),
      second: secondTest.map((s) => s.value[s.selected - 1]),
      third: thirdTest.model[Number(thirdTest.selected)],
      fourth: fourthTest.model[Number(fourthTest.selected)],
      fifth: fifthTest.model[Number(fifthTest.selected)],
      sixth: sixthTest.model[Number(sixthTest.selected)],
      seventh: seventhTest.model[Number(seventhTest.selected)],
      time_spent: new Date().valueOf() - startDate,
    };
    const userGetLocalStorage = localStorage.getItem("auth");
    const decrypt = userGetLocalStorage
      ? JSON.parse(decryptString(userGetLocalStorage, AppConfig.jwtSecret))
      : undefined;
    const token = decrypt && decrypt.token;
    fetch(AppConfig.host + "/model/result", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === 200) {
          navigate(`/result/${res.payload.id}`);
        }
        if (res.status === 401) {
          // console.log(first)
        }
      });
  };

  React.useEffect(() => {
    startDate = new Date().valueOf();
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.chapter}>
        <div
          className={classNames({
            [styles.chapter_item]: true,
            [styles.chapter_active]: chapter === 1,
          })}
        >
          <div className={styles.icon}>
            <Icon name="fa-solid fa-bullseye" size="1.6" />
          </div>
          Chapter 1
          <div className={styles.check_icon}>
            {chapter > 1 && <Icon name="fa-solid fa-circle-check" size="small" />}
          </div>
        </div>
        <div
          className={classNames({
            [styles.chapter_item]: true,
            [styles.chapter_active]: chapter === 2,
          })}
        >
          <div className={styles.icon}>
            <Icon name="fa-solid fa-pencil" size="1.6" />
          </div>
          Chapter 2
          <div className={styles.check_icon}>
            {chapter > 2 && <Icon name="fa-solid fa-circle-check" size="small" />}
          </div>
        </div>
        <div
          className={classNames({
            [styles.chapter_item]: true,
            [styles.chapter_active]: chapter >= 3,
          })}
        >
          <div className={styles.icon}>
            <Icon name="fa-solid fa-trophy" size="1.6" />
          </div>
          Chapter 3
          <div className={styles.check_icon}>
            {chapter > 7 && <Icon name="fa-solid fa-circle-check" size="small" />}
          </div>
        </div>
      </div>
      {chapter === 1 && (
        <div className={styles.main_container}>
          <Header
            title="Chapter 1"
            description={text.choose_what_you_like_the_most[lang]}
            fontSize="36px"
            className={styles.header}
          />
          <div className={styles.under_header}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {text.use_the_scale_below_to_indicate_how_much_you_like_each_item[lang]}
            </p>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "20px",
                marginTop: "10px",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon name="fa-regular fa-face-frown" size="small" /> <p>{text.very_bad[lang]}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon name="fa-regular fa-face-meh" size="small" /> <p>{text.bad[lang]}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon name="fa-regular fa-face-smile" size="small" /> <p>{text.neutral[lang]}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon name="fa-regular fa-face-smile-beam" size="small" /> <p>{text.good[lang]}</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon name="fa-regular fa-face-grin-hearts" size="small" />{" "}
                <p>{text.very_good[lang]}</p>
              </div>
            </div>
          </div>

          <div className={styles.chapter_list}>
            {firstTest.map((e, i) => (
              <ChooseEmojiComponent
                item={i + 1}
                text={e.label[lang]}
                key={i}
                number={e.rating}
                setNumber={(value) =>
                  setFirstTest((prev) =>
                    prev.map((p) => ({ ...p, rating: p.key === e.key ? value : p.rating }))
                  )
                }
              />
            ))}
          </div>

          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Button onClick={() => checkChapter1() && setChapter(2)}>
              {text.send[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
            </Button>
          </div>
        </div>
      )}
      {chapter === 2 && (
        <div className={styles.main_container}>
          <Header
            title="Chapter 2"
            description={text.choose_one_of_the_two[lang]}
            fontSize="36px"
            className={styles.header}
          />
          <div className={styles.under_header}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {text.for_each_question_below_choose_the_option_that_suits_you_best[lang]}
            </p>
          </div>
          <div className={styles.cards}>
            {secondTest.map((item) => (
              <div className={styles.card_group} key={item.key}>
                <button
                  className={classNames(styles.card, item.selected === 1 && styles.active)}
                  onClick={() =>
                    setSecondTest((prev) =>
                      prev.map((p) => ({ ...p, selected: item.key === p.key ? 1 : p.selected }))
                    )
                  }
                >
                  <img src={`/images/${item.cards[0]}`} />
                  {item.labels[0][lang]}
                </button>
                {text.or[lang]}
                <button
                  className={classNames(styles.card, item.selected === 2 && styles.active)}
                  onClick={() =>
                    setSecondTest((prev) =>
                      prev.map((p) => ({ ...p, selected: item.key === p.key ? 2 : p.selected }))
                    )
                  }
                >
                  <img src={`/images/${item.cards[1]}`} />
                  {item.labels[1][lang]}
                </button>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Button onClick={() => checkChapter2() && setChapter(3)}>
              {text.send[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
            </Button>
          </div>
        </div>
      )}
      {chapter === 3 && (
        <div className={styles.main_container}>
          <Header
            title="Chapter 3"
            description={thirdTest.question[lang]}
            fontSize="36px"
            className={styles.header}
          />
          <div className={styles.under_header}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {text.for_each_question_choose_the_option_that_best_suits_you[lang]}
            </p>
          </div>
          <div className={styles.checkboxes}>
            {thirdTest.list.map((item, index) => (
              <label className={styles.checkbox} key={item[lang]}>
                <input
                  name="capter-1"
                  type="radio"
                  checked={thirdTest.selected === String(index)}
                  onClick={() => setThirdTest((prev) => ({ ...prev, selected: String(index) }))}
                />
                {item[lang]}
              </label>
            ))}
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Button onClick={() => checkChapter3() && setChapter(4)}>
              {text.send[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
            </Button>
          </div>
        </div>
      )}
      {chapter === 4 && (
        <div className={styles.main_container}>
          <Header
            title="Chapter 3"
            description={fourthTest.question[lang]}
            fontSize="36px"
            className={styles.header}
          />
          <div className={styles.under_header}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {text.for_each_question_choose_the_option_that_best_suits_you[lang]}
            </p>
          </div>
          <div className={styles.checkboxes}>
            {fourthTest.list.map((item, index) => (
              <label className={styles.checkbox} key={item[lang]}>
                <input
                  name="capter-2"
                  type="radio"
                  checked={fourthTest.selected === String(index)}
                  onClick={() => setFourthTest((prev) => ({ ...prev, selected: String(index) }))}
                />
                {item[lang]}
              </label>
            ))}
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Button onClick={() => checkChapter4() && setChapter(5)}>
              {text.send[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
            </Button>
          </div>
        </div>
      )}
      {chapter === 5 && (
        <div className={styles.main_container}>
          <Header
            title="Chapter 3"
            description={fifthTest.question[lang]}
            fontSize="36px"
            className={styles.header}
          />
          <div className={styles.under_header}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {text.for_each_question_choose_the_option_that_best_suits_you[lang]}
            </p>
          </div>
          <div className={styles.checkboxes}>
            {fifthTest.list.map((item, index) => (
              <label className={styles.checkbox} key={item[lang]}>
                <input
                  name="capter-3"
                  type="radio"
                  checked={fifthTest.selected === String(index)}
                  onClick={() => setFifthTest((prev) => ({ ...prev, selected: String(index) }))}
                />
                {item[lang]}
              </label>
            ))}
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Button onClick={() => checkChapter5() && setChapter(6)}>
              {text.send[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
            </Button>
          </div>
        </div>
      )}
      {chapter === 6 && (
        <div className={styles.main_container}>
          <Header
            title="Chapter 3"
            description={sixthTest.question[lang]}
            fontSize="36px"
            className={styles.header}
          />
          <div className={styles.under_header}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {text.for_each_question_choose_the_option_that_best_suits_you[lang]}
            </p>
          </div>
          <div className={styles.checkboxes}>
            {sixthTest.list.map((item, index) => (
              <label className={styles.checkbox} key={item[lang]}>
                <input
                  name="capter-4"
                  type="radio"
                  checked={sixthTest.selected === String(index)}
                  onClick={() => setSixthTest((prev) => ({ ...prev, selected: String(index) }))}
                />
                {item[lang]}
              </label>
            ))}
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Button onClick={() => checkChapter6() && setChapter(7)}>
              {text.send[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
            </Button>
          </div>
        </div>
      )}
      {chapter === 7 && (
        <div className={styles.main_container}>
          <Header
            title="Chapter 3"
            description={seventhTest.question[lang]}
            fontSize="36px"
            className={styles.header}
          />
          <div className={styles.under_header}>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {text.for_each_question_choose_the_option_that_best_suits_you[lang]}
            </p>
          </div>
          <div className={styles.checkboxes}>
            {seventhTest.list.map((item, index) => (
              <label className={styles.checkbox} key={item[lang]}>
                <input
                  name="capter-5"
                  type="radio"
                  checked={seventhTest.selected === String(index)}
                  onClick={() => setSeventhTest((prev) => ({ ...prev, selected: String(index) }))}
                />
                {item[lang]}
              </label>
            ))}
          </div>
          <div
            style={{
              marginTop: "20px",
            }}
          >
            <Button onClick={() => checkChapter7() && handleSubmit()}>
              {text.send[lang]} <Icon name="fa-solid fa-arrow-right" size="small" />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
