import React, { useContext, useState } from "react";
import styles from "./login.styles.module.css";
import { LanguageContext } from "../../context/language-context/language.context";
import { text } from "../../text";
import { Input } from "../../component/input/input.component";
import { Button } from "../../component/button/button.component";
import { LoadingSpinner } from "../../component/loading-spinner/loading-spinner.component";
import { Icon } from "../../component/icon/icon.component";
import { useNavigate } from "react-router-dom";
import { sendPostRequest } from "../../util/send-post-request";
import { toast } from "react-toastify";
import { encryptString } from "../../util/decrypt-encrypt-string";
import { AppConfig } from "../../app/app.config";
import { AuthContext } from "../../context/auth-context/auth.context";
export default function LoginComponent() {
  const { lang } = useContext(LanguageContext);
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const [typePassword, setTypePassword] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { setAuthData } = useContext(AuthContext);

  async function hundleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setLoading(true);
    const res = await sendPostRequest("/user/login", data);
    if (res) {
      const json = await res.json();
      if (json.status === 200) {
        toast.success("User logined successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setAuthData(json.payload);
        localStorage.setItem(
          "auth",
          encryptString(JSON.stringify(json.payload), AppConfig.jwtSecret)
        );
        navigate("/");
      } else {
        toast.error("Email or Password is incorect", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    setLoading(false);
  }
  return (
    <div className={styles.container}>
      <form className={styles.modal_container} onSubmit={hundleSubmit}>
        <h3>{text.log_in_to_the_system[lang]}</h3>
        <label htmlFor="email">
          <span>{text.email[lang]}</span>
          <Input
            id="email"
            mode="primary"
            type="email"
            autoFocus={true}
            icon="fa-solid fa-x"
            onIconClick={() => {
              setData({
                ...data,
                email: "",
              });
            }}
            onValueChange={(e) => {
              setData({
                ...data,
                email: e,
              });
            }}
            value={data.email}
            required
          />
        </label>
        <label htmlFor="password">
          <span>{text.password[lang]}</span>
          <Input
            mode="primary"
            id="password"
            type={typePassword ? "password" : "text"}
            icon={
              typePassword ? "fa-regular fa-eye" : "fa-regular fa-eye-slash"
            }
            onIconClick={() => {
              setTypePassword(!typePassword);
            }}
            onValueChange={(e) => {
              setData({
                ...data,
                password: e,
              });
            }}
            value={data.password}
            required
          />
        </label>

        <Button mode="primary" disabled={loading}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {text.login[lang]}{" "}
              <Icon name="fa-solid fa-arrow-right" size="small" />
            </>
          )}
        </Button>
        <div
          style={{
            width: "90%",
            height: "20px",
            marginBottom: "20px",
            borderBottom: "1px solid silver",
          }}
        ></div>

        <Button
          type="button"
          mode="border"
          onClick={() => {
            navigate("/sign-up");
          }}
        >
          {text.registration[lang]}
        </Button>

        <p
          style={{
            cursor: "pointer",
            color: "blue",
            marginTop: "20px",
          }}
          onClick={() => {
            navigate("/forgot-password");
          }}
        >
          {text.forgot_password[lang]}
        </p>
      </form>
    </div>
  );
}
