import { useContext } from "react";
import "./app.styles.css";
import HeaderContainerComponent from "../component/header-container/header-container.component.tsx";
import HomeComponent from "../page/home/home.component.tsx";
import FooterComponent from "../component/footer/footer.component.tsx";
import { Navigate, Route, Routes } from "react-router-dom";
import SignUpComponent from "../page/sign-up/sign-up.component.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoginComponent from "../page/login/login.component.tsx";
import ForgotPasswordComponent from "../page/reset-passwrod/forgot-password.component.tsx";
import { AuthContext } from "../context/auth-context/auth.context.tsx";
import TestPageComponent from "../page/tests-page/test-page.component.tsx";
import FirstTestComponent from "../page/tests-pages/first-test.component.tsx";
import AccountComponent from "../page/account/account.component.tsx";
import ResultComponent from "../page/result/result.component.tsx";
import JobsComponent from "../page/jobs/jobs.component.tsx";
import SpecialityComponent from "../page/speciality/speciality.component.tsx";

export default function AppComponent() {
  const { authData } = useContext(AuthContext);

  return (
    <>
      <HeaderContainerComponent />
      <div className={"all_container"}>
        <Routes>
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path={"/"} element={<HomeComponent />} />
          {authData && <Route path="/test" element={<TestPageComponent />} />}
          {authData && <Route path="/test-11" element={<FirstTestComponent />} />}
          {authData && <Route path="/account" element={<AccountComponent />} />}
          {authData && <Route path="/result/:id" element={<ResultComponent />} />}
          {authData && <Route path="/jobs/:id" element={<JobsComponent />} />}
          {authData && <Route path="/speciality/:id" element={<SpecialityComponent />} />}

          {!authData && <Route path="/sign-up" element={<SignUpComponent />} />}
          {!authData && <Route path="/login" element={<LoginComponent />} />}
          {!authData && <Route path="/forgot-password" element={<ForgotPasswordComponent />} />}
        </Routes>
      </div>
      <FooterComponent />
      <ToastContainer />
    </>
  );
}
