import { createContext } from "react";

export type AlertOptions = {
  text: string;
  icon?: string;
  mode: "simple" | "regular" | "success" | "danger";
};

export type AlertContextType = {
  open(options: AlertOptions): void;
  close(): void;
};

export const AlertContext = createContext<AlertContextType>({
  open() {},
  close() {},
});
