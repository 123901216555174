import classNames from "classnames"
import { ReactNode, useEffect, useRef, useState } from "react"
import {
  PopupOpenerContext,
  PopupOpenerContextType,
} from "./popup-opener.context"
import { Popup } from "./popup.component"
import styles from "./popup.styles.module.css"
import { PopupOpenerProps, PopupOptions, PopupPosition } from "./popup.types"

export function PopupOpener({
  mode,
  placement,
  offset,
  children,
  content,
  className,
  style,
  popupStyle,
  disabled = false,
  closeOnInteract = false,
  onOpen,
  onClose,
}: PopupOpenerProps) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const ref = useRef<HTMLDivElement>(null)
  const [popupOptions, setPopupOptions] = useState<PopupOptions>({})
  const [popupContent, setPopupContent] = useState<ReactNode>(null)

  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  useEffect(() => {
    if (isOpened()) {
      setPopupContent(typeof content === "function" ? content() : content)
    } else {
      setPopupContent(null)
    }
  }, [content])

  useEffect(() => {
    setPopupOptions((options) => ({
      ...options,
      style: popupStyle,
      placement,
      offset,
      closeOnInteract,
    }))
  }, [popupStyle, placement, offset, closeOnInteract])

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  function open(position: PopupPosition) {
    if (popupContent === null) {
      setPopupContent(typeof content === "function" ? content() : content)
    }
    setPopupOptions((options) => ({
      ...options,
      position,
    }))
    onOpen?.()
  }

  function close() {
    setPopupOptions({ ...popupOptions, position: undefined })
  }

  function isOpened() {
    return popupOptions.position !== undefined
  }

  function openComponent({ offset, content, popupStyle }: PopupOpenerProps) {
    setPopupOptions((options) => ({
      ...options,
      style: popupStyle,
      offset,
    }))

    if (isOpened()) {
      setPopupContent(typeof content === "function" ? content() : content)
    } else {
      setPopupContent(null)
    }
  }

  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [value] = useState<PopupOpenerContextType>({
    open: openComponent,
    close: close,
    setOptions: setPopupOptions,
  })

  // ---------------------------------------------------------------------------
  return (
    <PopupOpenerContext.Provider value={value}>
      <>
        <Popup
          options={popupOptions}
          onOptionsChange={setPopupOptions}
          onClose={onClose}
        >
          {popupContent}
        </Popup>
        <div
          ref={ref}
          style={style}
          className={classNames(styles.popup, className)}
          onClick={(e) => {
            if (mode !== "click") return
            if (disabled) return
            if (isOpened()) return
            e.preventDefault()
            open(ref)
          }}
          onContextMenu={(e) => {
            if (mode !== "context") return
            if (disabled) return
            if (isOpened()) return
            e.preventDefault()
            open({
              x: e.clientX,
              y: e.clientY,
            })
          }}
        >
          {children}
        </div>
      </>
    </PopupOpenerContext.Provider>
  )
}
