import React, { useContext } from "react";
import { LanguageContext } from "../../context/language-context/language.context";
import { text } from "../../text";
import styles from "./account.styles.module.css";
import { Link } from "react-router-dom";
import { sendGetRequest } from "../../util/send-get-request";

interface ITest {
  id: number;
  name: string;
  email: string;
  time: number;
  modelResults: [number, number, number, number, number];
  data: string;
}

export default function AccountComponent() {
  const { lang } = useContext(LanguageContext);

  const [testList, setTestList] = React.useState([]);

  React.useEffect(() => {
    const getData = async () => {
      const result = await sendGetRequest("/get/model/results").then((res) => res?.json());
      setTestList(result.payload);
    };
    getData();
  }, []);

  return (
    <div className={styles.contianer}>
      <div className={styles.title}>{text.the_result_of_the_test[lang]}</div>
      <div className={styles.test_list}>
        {testList.map((test: ITest) => (
          <Link
            to={`/result/${test.id}`}
            key={test.id}
            draggable={false}
            className={styles.test_item}
          >
            <div className={styles.test_item_title}>{test.name}</div>
            <div className={styles.test_item_description}>
              Сана: {new Date(test.time).toLocaleString()}
            </div>
            <div className={styles.test_item_icon}>
              <i className="fa-solid fa-circle-info " />
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}
