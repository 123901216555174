import { ReactNode, useCallback, useState } from "react";
import { AlertContext, AlertContextType, AlertOptions } from "./alert.context";
import { Alert } from "./alert.component";

export function AlertProvider(props: { children: ReactNode }) {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [isRendered, setRendered] = useState<boolean>(false);
  const [isOpened, setOpened] = useState<boolean>(false);
  const [options, setOptions] = useState<AlertOptions | null>(null);

  // ---------------------------------------------------------------------------
  // memo callbacks
  // ---------------------------------------------------------------------------

  const openFn = useCallback((options: AlertOptions) => {
    setRendered(true);
    setTimeout(() => {
      setOpened(true);
      if (options) setOptions(options);

      setTimeout(() => {
        closeFn();
      }, 3000);
    });
  }, []);

  const closeFn = useCallback(() => {
    setOpened(false);
    setOptions(null);
    setTimeout(() => {
      setRendered(false);
    }, 300);
  }, []);

  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------

  const [value] = useState<AlertContextType>({
    open: openFn,
    close: closeFn,
  });

  // ---------------------------------------------------------------------------
  return (
    <AlertContext.Provider value={value}>
      {options && (
        <Alert
          isOpened={isOpened}
          options={options}
          close={closeFn}
          style={{ visibility: isRendered ? "visible" : "hidden" }}
        />
      )}
      {props.children}
    </AlertContext.Provider>
  );
}
