import { useContext, useState } from "react";
import { Header } from "../../component/header/header.component";
import { text } from "../../text";
import { LanguageContext } from "../../context/language-context/language.context";
import styles from "./test-page.styles.module.css";
import Card from "../../component/card/card.component";
import { Modal } from "../../component/modal";
import { Button } from "../../component/button/button.component";
import { Icon } from "../../component/icon/icon.component";
import { useNavigate } from "react-router-dom";

export default function TestPageComponent() {
  function Component() {
    return (
      <div
        style={{
          maxWidth: "400px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          padding: "10px",
        }}
      >
        <img
          src="/images/article-img.png"
          width={"300px"}
        />
        <Header
          title={text.a_test_for_choosing_a_professioncluster_using_artificial_intelligence[lang]}
          description={
            text
              .with_the_help_of_artificial_intelligence_algorithms_based_on_your_interests_and_skills_determine_which_cluster_of_specializations_is_suitable_for_you_suggest_a_suitable_cluster_based_on_the_students_interests_and_skills_the_result_of_the_test_is_only_an_advice_and_the_final_conclusion_is_in_your_hands_to_the_future_with_your_dreams[
              lang
            ]
          }
          fontSize="20px"
        />
      </div>
    );
  }

  function TextForTestComponent() {
    const navigate = useNavigate();
    return (
      <div>
        <Header
          title={text.a_test_for_choosing_a_professioncluster_using_artificial_intelligence[lang]}
          description={`${text.number_of_questions[lang]}7`}
          fontSize="20px"
        />
        <p
          style={{
            marginBottom: "20px",
          }}
        >
          {
            text
              .with_the_help_of_artificial_intelligence_algorithms_based_on_your_interests_and_skills_determine_which_cluster_of_specializations_is_suitable_for_you_suggest_a_suitable_cluster_based_on_the_students_interests_and_skills_the_result_of_the_test_is_only_an_advice_and_the_final_conclusion_is_in_your_hands_to_the_future_with_your_dreams[
              lang
            ]
          }
        </p>

        <Button mode="primary" onClick={() => navigate("/test-11")}>
          {text.start[lang]} <Icon name={"fa-solid fa-arrow-right"} size={"1.6"} />
        </Button>
      </div>
    );
  }
  const { lang } = useContext(LanguageContext);
  const [modal, setMoadl] = useState(false);
  return (
    <div className={styles.container}>
      <Modal
        isOpened={modal}
        close={() => setMoadl(false)}
        options={{
          closeOnEsc: true,
          component: <TextForTestComponent />,
          closeOnOutsideClick: true,
        }}
      />
      <div className={styles.main_container}>
        <Header
          title={`${text.tests[lang]}`}
          description={`${text.choose_the_test[lang]}`}
          fontSize="36px"
          className={styles.header_container}
        />
        <div className={styles.container_tests}>
          <Card
            component={<Component />}
            onClick={() => {
              setMoadl(true);
            }}
          />
        </div>
      </div>
    </div>
  );
}
