import React from "react";
import styles from "./video.styles.module.css";
const VideoComponent = (props: { src: string }) => {
  const ref = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    ref.current?.focus();
    ref.current?.play();
  }, []);

  return <video autoPlay ref={ref} className={styles.container} loop muted src={props.src} />;
};

export default VideoComponent;
