import { useMemo, useState } from "react";
import { ThemeContext, ThemeContextType } from "./theme.context.tsx";

export function ThemeProvider(props: { children: React.ReactNode }) {
  const [theme, setTheme] = useState<ThemeContextType>("light");

  const contextValue = useMemo(() => {
    const toggleTheme = () => {
      setTheme(theme === "light" ? "dark" : "light");
    };

    return { theme, toggleTheme };
  }, [theme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {props.children}
    </ThemeContext.Provider>
  );
}
