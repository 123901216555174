import { ReactNode } from "react";
import styles from "./header.styles.module.css";
import classNames from "classnames";

export function Header({
  className,
  title,
  description,
  link,
  fontSize,
}: {
  className?: string;
  title: string;
  description?: string;
  link?: ReactNode;
  fontSize?: string;
}) {
  return (
    <header className={classNames(styles.base, className ? className : "")}>
      <div className={styles.wrapper}>
        <h1 style={{ fontSize: `${fontSize}` }}>{title}</h1>
        {link && <div className={styles.link}>{link}</div>}
      </div>
      {description && <p className={styles.description}>{description}</p>}
    </header>
  );
}
