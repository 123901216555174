export const text = {
  start: {
    en: "Start",
    ru: "Начать",
    tj: "Оғоз",
  },
  build_your_future: {
    en: "Build your future!",
    ru: "Построй свое будущее!",
    tj: "Ояндаи худро созед!",
  },

  zypl_career: {
    en: "Zypl.career - The way to your professional success! It offers materials suitable for everyone's interests and skills! Your future is in your hands and Zypl.career will help you in this way!",
    ru: "Zypl.career (Зипл.карйер) - ваш путь к профессиональному успеху! Он предлагает материалы, соответствующие интересам и навыкам каждого! Ваше будущее - в Ваших руках, и Zypl.career всегда будет вам помогать в этом!",
    tj: "Zypl.Career (Зипл.карйер) - Дарест ба сӯи муваффақияти касбии ту! Барои ҳар шахс маводҳои мувофиқ ба шавқу рағбат ва малакааш пешниҳод менамояд! Ояндаи ту дар дасти туст ва zypl.career дар ин роҳ ёварест ба ту!",
  },

  helping_others_choose_a_career: {
    en: "Helping others choose a career?",
    ru: "Помогать другим в выборе профессии?",
    tj: "Ба дигарон барои интихоби касб кумак мекунед?",
  },

  tools_for_inspiration_and_motivation: {
    en: "Tools for inspiration and motivation.",
    ru: "Средства для вдохновения и мотивации.",
    tj: "Воситаҳо барои илҳому ҳавасмандӣ.",
  },

  use_these_resources: {
    en: "Use these resources to learn more about your interests, values, and skills AND their connection to future education and personal development!",
    ru: "Используйте эти ресурсы, чтобы узнать больше о своих интересах, ценностях и навыках И об их связи с будущим образованием и личностным развитием!",
    tj: "Барои омӯзиши бештари шавқ, арзишҳо ва малакаҳои худ ВА пайвастагии онҳо бо таҳсилоти оянда ва рушди кабӣ аз ин маводҳо истифода баред!",
  },

  database_of_test_center_specialties: {
    en: "Database of test center specialties",
    ru: "База данных специальностей центра тестирования",
    tj: "Пойгоҳи ихтисосҳои маркази тестӣ",
  },

  through_this_database: {
    en: "Through this database, you can find all the specialties and necessary information on the specialties",
    ru: "В этой базе данных можно найти все специальности и необходимую информацию по ним",
    tj: "Тавассути ин пойгоҳ, шумо ба тамоми ихтисосҳо ва маълумоти зарурӣ оид ба ихтисосҳо пайдо карда метавонед",
  },

  journalism: {
    en: "Journalism (C3. Philology, pedagogy, and art)",
    ru: "Журналистика (К3. Филология, педагогика и искусство)",
    tj: "Рӯзноманигорӣ/журналистика (К3. Филология, педагогика ва санъат)",
  },

  approximate: {
    en: "Approximate monthly salary",
    ru: "Примерная месячная зарплата",
    tj: "Маоши тақрибии ҳармоҳа",
  },

  requirements: {
    en: "Requirements for this profession",
    ru: "Требования к данной профессии",
    tj: "Талабот ба ин касби",
  },

  stable: {
    en: "Stable/unchanged",
    ru: "Стабильный/неизменный",
    tj: "Муътадил/бетаъғир",
  },

  diploma: {
    en: "Diploma",
    ru: "Диплом",
    tj: "Диплом",
  },

  a_diploma_is_required: {
    en: "A diploma is required",
    ru: "Необходим диплом",
    tj: "Диплом лозим аст",
  },

  in_educational_institutions: {
    en: "In educational institutions",
    ru: "В учебных заведениях",
    tj: "Дар муассисаҳои таълимӣ",
  },

  it_takes_a_minute_to_read: {
    en: "It takes a minute to read",
    ru: "Для ознакомления потребуется одна минута",
    tj: "Дақиқа вақт мегирад барои хондан",
  },

  database_of_specialties: {
    en: "Database of specialties",
    ru: "База данных специальностей",
    tj: "Пойгоҳи ихтисосҳо",
  },

  specialties: {
    en: "Specialties",
    ru: "Специальности",
    tj: "Ихтисосҳо",
  },

  copyrights_are_protected: {
    en: "Copyrights are protected",
    ru: "Авторские права защищены",
    tj: "Ҳуқуқҳои муаллифон ҳимоя шуда аст",
  },

  user_agreement: {
    en: "User Agreement",
    ru: "Пользовательское соглашение",
    tj: "Шартномаи истифодабарӣ",
  },

  professional_advice: {
    en: "Professional advice",
    ru: "Профессиональные консультации",
    tj: "Машваратҳои касбӣ",
  },

  get_career_information_directly_to_your_email: {
    en: "Get career information directly to your email",
    ru: "Информация о карьере прямо на вашу электронную почту",
    tj: "Маълумот оид ба касбро бевосита ба пуштаи электронии худ гиред",
  },

  be_one_step_ahead: {
    en: "Be one step ahead! Sign up for updates to keep up to date with the latest information on careers, courses, career paths, career options, and events.",
    ru: "Будьте на шаг впереди! Подпишитесь, чтобы всегда быть в курсе самой актуальной информации о карьере, курсах, видах профессиональной деятельности, вариантах выбора профессии и мероприятиях.",
    tj: "Як қадам пеш бошед! Барои гирифтани навгониҳо обуна шавед, то аз маълумоти нав дар бораи касбҳо, курсҳо, пайроҳаҳои касбӣ, интихоби касб ва чорабиниҳо бохабар бошед.",
  },

  email: {
    en: "Email",
    ru: "Email",
    tj: "Почтаи электронӣ",
  },

  subscribe: {
    en: "Subscribe",
    ru: "Подписаться",
    tj: "Обуна шудан",
  },

  start_building_your_professional_profile: {
    en: "Start building your professional profile",
    ru: "Начните создавать свой профессиональный профиль",
    tj: "Сохтани профили касбии худро оғоз кунед",
  },

  go_through_our: {
    en: "Go through our tests to identify your interests, values, and skills. Browse careers and courses on offer.",
    ru: "Пройдите наши тесты, чтобы определить свои интересы, ценности и навыки. Ознакомьтесь с профессиями и курсами, которые мы предлагаем.",
    tj: "Барои муайян кардани манфиатҳо, арзишҳо ва малакаҳои худ супоришҳоро иҷро кунед. Касбҳо ва курсҳои пешниҳодшударо аз назар гузаронед.",
  },

  pass_the_test: {
    en: "Pass the test",
    ru: "Пройти тест",
    tj: "Гузаштани тест",
  },

  learn_about_yourself: {
    en: "Learn about yourself",
    ru: "Узнать о себе",
    tj: "Дар бораи худ биомӯзед",
  },

  my_professional_profile: {
    en: "My professional profile",
    ru: "Мой профессиональный профиль",
    tj: "Профили касбии ман",
  },

  professional_success: {
    en: "Professional success",
    ru: "Профессиональный успех",
    tj: "Муваффақияти касбӣ",
  },

  finding_opportunities: {
    en: "Finding opportunities",
    ru: "Поиск возможностей",
    tj: "Пайдокунии имкониятҳо",
  },

  occupations: {
    en: "Occupations",
    ru: "Профессии",
    tj: "Касбҳо",
  },

  courses: {
    en: "Courses",
    ru: "Курсы",
    tj: "Курсҳо",
  },

  sectors: {
    en: "Sectors",
    ru: "Отрасли",
    tj: "Соҳаҳо",
  },

  help_others: {
    en: "Help others",
    ru: "Помощь другим",
    tj: "Кӯмак ба дигарон",
  },

  student: {
    en: "Student",
    ru: "Студент",
    tj: "Донишомӯз",
  },

  educational_institutions: {
    en: "Educational institutions",
    ru: "Учебные заведения",
    tj: "Муассисаҳои таълимӣ",
  },

  registration: {
    en: "Registration",
    ru: "Регистрация",
    tj: "Бақайдгирӣ",
  },

  log_in_to_the_system: {
    en: "Login",
    ru: "Вход в систему",
    tj: "Воридшавӣ ба система",
  },

  sign_in_with_password: {
    en: "Sign in with password",
    ru: "Войти в систему с кодом",
    tj: "Воридшавӣ бо рамз",
  },

  date_of_birth: {
    en: "Date of birth",
    ru: "Дата рождения",
    tj: "Санаи таваллуд",
  },

  day: {
    en: "Day",
    ru: "День",
    tj: "Рӯз",
  },

  month: {
    en: "Month",
    ru: "Месяц",
    tj: "Моҳ",
  },

  year: {
    en: "Year",
    ru: "Год",
    tj: "Сол",
  },

  city_district_of_residence: {
    en: "City/district of residence",
    ru: "Город/район проживания",
    tj: "Шаҳр/ноҳияи истиқомат",
  },

  select_a_city: {
    en: "Select a city",
    ru: "Выберите город",
    tj: "Шаҳрро интихоб кунед",
  },

  your_gender: {
    en: "Your Gender",
    ru: "Ваш пол",
    tj: "Ҷинси шумо",
  },

  select_gender: {
    en: "Select gender",
    ru: "Выберите пол",
    tj: "Ҷинсро интихоб кунед",
  },

  symbol: {
    en: "Symbol",
    ru: "Символ",
    tj: "Рамз",
  },

  enter_the_password: {
    en: "Enter the password",
    ru: "Введите пароль",
    tj: "Рамзро ворид кунед",
  },

  enter_the_code_again: {
    en: "Enter the code again",
    ru: "Введите код еще раз",
    tj: "Рамзро такроран ворид кунед",
  },
  copyright_all_rights_reserved: {
    en: "Copyright. All rights reserved.",
    ru: "Авторские права. Все права защищены.",
    tj: "Ҳуқуқҳои муаллифон ҳимоя шуда аст.",
  },

  name: {
    en: "Name",
    ru: "Имя",
    tj: "Ном",
  },

  choose: {
    en: "Choose",
    ru: "Выбирать",
    tj: "Интихоб кунед",
  },

  man: {
    en: "Male",
    ru: "Мужской",
    tj: "Мард",
  },

  woman: {
    en: "Female",
    ru: "Женский",
    tj: "Зан",
  },

  password: {
    en: "Password",
    ru: "Пароль",
    tj: "Рамз",
  },
  repeat_password: {
    en: "Repeat password",
    ru: "Повторите пароль",
    tj: "Такрори рамз",
  },

  passwords_do_not_match: {
    en: "passwords do not match",
    ru: "пароли не совпадают",
    tj: "Номувофики паролхо",
  },
  confirm: {
    en: "Confirm",
    ru: "Подтвердить",
    tj: "Тасдиқ",
  },
  confirm_email: {
    en: "Confirm your email address",
    ru: "Подтвердите ваш электронный почта",
    tj: "Почтаи электронии худро тасдиқ кунед",
  },

  we_have_sent: {
    en: "We have sent a four-digit code to your email",
    ru: "Мы отправили четырехзначный код на вашу электронную почту",
    tj: "Мо ба почтаи электронии шумо рамзи чор рақам фиристодаем",
  },

  login: {
    en: "Login",
    ru: "Вход",
    tj: "Воридшавӣ",
  },
  user_successfully: {
    en: "User registered successfully!",
    ru: "Пользователь успешно зарегистрирован!",
    tj: "Корбар бомуваффақият сабти ном шуд!",
  },

  your_code_is_wrong: {
    en: "Your code is wrong!",
    ru: "Ваш код неправильный!",
    tj: "Рамзи шумо нодуруст аст!",
  },

  forgot_password: {
    en: "forgot password",
    ru: "забыли пароль",
    tj: "рамзи худро фаромӯш кардаед",
  },
  password_recovery: {
    en: "Password recovery",
    ru: "Восстановление пароля",
    tj: "Барқароркунии рамз",
  },

  recovery: {
    en: "Restore",
    ru: "Восстановить",
    tj: "Барқарор кардан",
  },

  old_Password: {
    en: "Old Password",
    ru: "Старый пароль",
    tj: "Рамзи куҳна",
  },
  new_passwrod: {
    en: "New passwrod",
    ru: "Новый пароль",
    tj: "Рамзи Нав",
  },

  repeat_new_password: {
    en: "Repeat new password",
    ru: "Повторите новый пароль",
    tj: "Рамзи навро такрор кунед",
  },

  my_page: {
    en: "My page",
    ru: "Моя страница",
    tj: "Саҳифаи ман",
  },
  log_out: {
    en: "Log out",
    ru: "",
    tj: "",
  },

  tests: {
    en: "Tests",
    ru: "Тесты",
    tj: "Тестҳо",
  },

  choose_the_test: {
    en: "Choose the test of your choice to get tips for choosing your future career",
    ru: "Выберите тест по вашему выбору, чтобы получить советы по выбору будущей карьеры",
    tj: "Тести дилхоҳатонро интихоб кунед, то барои интихоби касби ояндаатон маслиҳатҳо гиред",
  },

  a_test_for_choosing_a_professioncluster_using_artificial_intelligence: {
    en: "A test for choosing a profession/cluster using Artificial Intelligence",
    ru: "Тест на выбор профессии/кластера с использованием искусственного интеллекта",
    tj: "Тест барои интихоби касб/кластер бо истифода аз Зеҳни сунъӣ",
  },
  english: { en: "ENGLISH", ru: "RUSSIAN", tj: "TAJIK" },
  number_of_questions: {
    en: "Number of questions:",
    ru: "Количество вопросов:",
    tj: "Миқдори саволҳо: ",
  },
  with_the_help_of_artificial_intelligence_algorithms_based_on_your_interests_and_skills_determine_which_cluster_of_specializations_is_suitable_for_you_suggest_a_suitable_cluster_based_on_the_students_interests_and_skills_the_result_of_the_test_is_only_an_advice_and_the_final_conclusion_is_in_your_hands_to_the_future_with_your_dreams:
    {
      en: "With the help of artificial intelligence algorithms, based on your interests and skills, determine which cluster of specializations is suitable for you. Suggest a suitable cluster based on the student's interests and skills. THE RESULT OF THE TEST IS ONLY AN ADVICE AND THE FINAL CONCLUSION IS IN YOUR HANDS! TO THE FUTURE WITH YOUR DREAMS!",
      ru: " С помощью алгоритмов искусственного интеллекта на основе ваших интересов и навыков определить, какой кластер специализаций вам подходит. Предложить подходящий кластер, исходя из интересов и навыков студента. РЕЗУЛЬТАТ ТЕСТА - ЭТО ТОЛЬКО СОВЕТ, А ОКОНЧАТЕЛЬНЫЙ ВЫВОД - В ВАШИХ РУКАХ! В БУДУЩЕЕ ВМЕСТЕ С ВАШИМИ МЕЧТАМИ!",
      tj: "Бо ёрии алгоритмҳои зеҳни сунъӣ, дар асоси шавқу рағбат ва малакаатон муайян кунед, ки кадом кластер ба ихтисосҳо ба шумо мувофиқанд. Пешниҳоди кластери мувофиқ дар асоси шавқу рағбат ва малакаҳои хонанда. НАТИҶАИ ТЕСТ ТАНҲО МАСЛИҲАТЕ МЕБОШАД ва ХУЛОСАИ НИҲОӢ ДАР ДАСТИ ШУМОСТ! БА ОЯНДА БО СӮИ ОРЗӮҲО!",
    },
  start_the_test: { en: "Start the test", ru: "Начать тест", tj: "Оғози тест" },
  choose_what_you_like_the_most: {
    en: "Choose what you like the most.",
    ru: "Выберите то, что вам больше всего нравится.",
    tj: "Он чиро, ки ба шумо бештар маъқул аст интихоб кунед.",
  },
  use_the_scale_below_to_indicate_how_much_you_like_each_item: {
    en: "Use the scale below to indicate how much you like each item.",
    ru: "С помощью приведенной ниже шкалы укажите, насколько вам нравится каждый пункт.",
    tj: "Барои нишон додани он, ки ҳар банд ба Шумо то чӣ андоза маъқул, аз шкалаи зерин истифода баред.",
  },
  very_bad: {
    en: "- Very bad",
    ru: "- Очень плохо",
    tj: "- Хело бад",
  },
  bad: {
    en: "- Bad",
    ru: "- Плохо",
    tj: "- Бад",
  },
  neutral: {
    en: "- Neutral, 50 to 50",
    ru: "- Нейтрально, 50 на 50",
    tj: "- Бетараф, 50 ба 50 ",
  },
  good: {
    en: "- Good",
    ru: "- Хорошо",
    tj: "- Хуб",
  },
  very_good: {
    en: "- Very good",
    ru: "- Очень хорошо",
    tj: "- Хеле хуб",
  },

  prepare_a_budget_for_any_enterprise: {
    en: "Prepare a budget for any enterprise.",
    ru: "Подготовка бюджета для любого предприятия.",
    tj: "Барои ягон корхона буҷет тартиб додан.",
  },
  promotion_of_enterprisebusiness_documentation: {
    en: "Promotion of enterprise/business documentation.",
    ru: "Продвижение документации предприятия/бизнеса.",
    tj: "Пешбарии ҳуҷҷатгузории корхона/бизнес.",
  },
  calculate_the_interest_on_the_loan: {
    en: "Calculate the interest on the loan.",
    ru: "Рассчитать проценты по кредиту.",
    tj: "Фоизи қарзро ҳисоб кардан.",
  },
  installing_a_light_switch: {
    en: "Installing a light switch.",
    ru: "Установка выключателя/выключателя света.",
    tj: "Калиди барқии лампаро (включатель) насб кардан.",
  },
  repairing_motorcycles_and_bicycles: {
    en: "Repairing motorcycles and bicycles.",
    ru: "Ремонт мотоциклов и велосипедов.",
    tj: "Мототсиклҳо ва велосипедҳоро таъмир кардан.",
  },
  singing_or_playing_an_instrument_in_a_band_or_composing_music: {
    en: "Singing or playing an instrument in a band, or composing music.",
    ru: "Петь или играть на инструменте в группе, или сочинять музыку.",
    tj: "Дар гурӯҳи мусиқӣ суруд хондан ё асбоб навохтан, ё мусиқӣ эҷод кардан.",
  },
  explore_your_favorite_music_genre: {
    en: "Explore your favorite music genre.",
    ru: "Изучите свой любимый музыкальный жанр.",
    tj: "Навъи мусиқии дӯстдоштаи худро омӯхтан.",
  },
  compare_the_prices_of_similar_goods_and_decide_which_one_is_the_best: {
    en: "Compare the prices of similar goods and decide which one is the best.",
    ru: "Сравните цены на аналогичные товары и решите, какой из них лучше.",
    tj: "Нархи хуроквории ба ҳам монандро муқоиса кардан ва қарор кунед, ки кадомаш беҳтар аст.",
  },
  teaching_children_with_special_educational_needs: {
    en: "Teaching children with special educational needs.",
    ru: "Обучение детей с особыми образовательными потребностями.",
    tj: "Таълим бо кӯдаконе, ки дар таҳсил ба кӯмаки махсус ниёз доранд.",
  },
  repair_of_water_pipes: {
    en: "Repair of water pipes.",
    ru: "Ремонт водопроводных труб.",
    tj: "Таъмири қубурҳои обгузар.",
  },
  working_with_youth_in_extracurricular_activities: {
    en: "Working with youth in extracurricular activities.",
    ru: "Работа с молодежью на внеклассных занятиях.",
    tj: "Кор бо ҷавонон дар машғулиятҳои берун аз дарсӣ.",
  },
  arranging_decorating_trees_or_bushes: {
    en: "Arranging, decorating trees or bushes.",
    ru: "Расстановка, украшение деревьев или кустарников.",
    tj: "Дарахтон ё буттаҳоро ба тартиб даровардан, ороиш додан.",
  },
  find_out_why_there_are_big_waves_in_the_ocean: {
    en: "Find out why there are big waves in the ocean.",
    ru: "Выяснить, почему в океане бывают большие волны.",
    tj: "Бифаҳмед, ки чаро дар уқёнус мавҷҳо калон мавҷуданд.",
  },
  design_of_clothes: {
    en: "Design of clothes.",
    ru: "Дизайн одежды.",
    tj: "Тарҳрезии либосҳо.",
  },
  motivating_people_to_get_things_done: {
    en: "Motivating people to get things done.",
    ru: "Мотивирование людей на выполнение работы.",
    tj: "Одамонро барои анҷом додани кор ҳавасманд кардан.",
  },
  study_the_effects_of_radiation: {
    en: "Study the effects of radiation.",
    ru: "Изучение воздействия радиации.",
    tj: "Омӯзиши таъсири радиатсионӣ.",
  },
  volunteering_in_public_organizations_and_delivering_food_to_families_with_needs: {
    en: "Volunteering in public organizations and delivering food to families with needs.",
    ru: "Быть волонтером в общественных организациях и доставлять продукты питания в нуждающиеся семьи.",
    tj: "Волонтёр дар ташкилотҳои ҷамъиятӣ кор кардан ва расонидани хӯрок ба оилаҳои ниёзманд.",
  },
  write_a_poem_about_someone_or_something: {
    en: "Write a poem about someone or something.",
    ru: "Написать стихотворение о ком-то или о чем-то.",
    tj: "Дар бораи касе ё чизе шеър нависед.",
  },
  repairing_a_broken_car: {
    en: "Repairing a broken car.",
    ru: "Ремонт неисправного автомобиля.",
    tj: "Мошини вайроншударо таъмир кардан.",
  },
  speech_to_motivate_people: {
    en: "Speech to motivate people.",
    ru: "Речь для мотивации людей.",
    tj: "Суханронӣ барои ҳавасмандкунии одамон.",
  },
  participation_in_the_meeting_of_scientists: {
    en: "Participation in the meeting of scientists.",
    ru: "Участие в собрании ученых.",
    tj: "Дар вохӯрии олимон иштирок кардан.",
  },
  create_a_schedule_of_business_transactions: {
    en: "Create a schedule of business transactions.",
    ru: "Составление графика деловых операций.",
    tj: "Ҷадвали муомилоти тиҷоратиро тартиб додан.",
  },
  being_the_head_of_an_organization: {
    en: "Being the head of an organization.",
    ru: "Быть руководителем организации.",
    tj: "Роҳбари ягон ташкилот будан.",
  },
  working_with_adults_from_other_families: {
    en: "Working with adults from other families.",
    ru: "Работа со взрослыми из других семей.",
    tj: "Кор бо калонсолон аз оилаҳои дигар.",
  },
  patient_support_in_the_hospital: {
    en: "Patient support in the hospital.",
    ru: "Помощь пациентам в больнице.",
    tj: "Дастгирии беморон дар беморхона.",
  },
  serve_as_a_club_or_organization_leader: {
    en: "Serve as a club or organization leader.",
    ru: "Быть руководителем клуба или организации.",
    tj: "Ба сифати роҳбари клуб ё ташкилот хизмат кардан.",
  },
  convince_others_to_invest_in_your_company: {
    en: "Convince others to invest in your company.",
    ru: "Убедите других инвестировать в вашу компанию.",
    tj: "Дигаронро бовар кунонед, ки ба ширкати шумо сармоягузорӣ кунанд.",
  },
  installing_new_shelvesfurniture_in_a_room: {
    en: "Installing new shelves/furniture in a room.",
    ru: "Установка новых полок/мебели в помещении.",
    tj: "Дар як ҳуҷра рафҳо/мебели нав насб кардан.",
  },
  repairing_damaged_equipment: {
    en: "Repairing damaged equipment.",
    ru: "Ремонт вышедшего из строя оборудования.",
    tj: "Асбоби вайроншударо таъмир кардан.",
  },
  get_acquainted_with_the_latest_achievements_in_science_and_technology: {
    en: "Get acquainted with the latest achievements in science and technology.",
    ru: "Ознакомиться с последними достижениями науки и техники.",
    tj: "Бо комёбиҳои охирини илм ва техника шинос шудан.",
  },
  scene_design_for_a_scenedramaor_video_shoot: {
    en: "Scene design for a scene/drama/or video shoot.",
    ru: "Оформление сцены для сцен/драм/видеосъемок.",
    tj: "Тарҳрезии саҳна барои саҳнача/драма/ё банаворгирии клип..",
  },
  send: { en: "Send", ru: "Отправить", tj: "Фиристодан" },
  cancel: { en: "Cancel", ru: "Отменить", tj: "Бекоркунӣ" },

  choose_one_of_the_two: {
    en: "Choose one of the two.",
    ru: "Выберите один из двух вариантов.",
    tj: "Яке аз дуро интихоб кунед.",
  },

  for_each_question_below_choose_the_option_that_suits_you_best: {
    en: "For each question below, choose the option that suits you best.",
    ru: "Для каждого из приведенных ниже вопросов выберите вариант, который подходит Вам больше всего.",
    tj: "Барои ҳар як саволи зер вариантеро, ки ба шумо бештар маъқул аст, интихоб кунед.",
  },
  education: { en: "Education", ru: "Образование", tj: "Маориф" },
  or: { en: "or", ru: "или", tj: "ё" },
  art: { en: "Art", ru: "Искусство", tj: "Санъат" },
  company_management: {
    en: "Company management",
    ru: "Управление компанией",
    tj: "Идоракунии ширкат",
  },
  helping_families_in_need: {
    en: "Helping families in need",
    ru: "Помощь нуждающимся семьям",
    tj: "Кӯмак ба оилаҳои ниёзманд",
  },
  work_in_the_office: {
    en: "Work in the office",
    ru: "Работа в офисе",
    tj: "Кор дар офис",
  },
  description_and_explanation_of_pictures: {
    en: "Description and explanation of pictures",
    ru: "Описание и объяснение фотографий",
    tj: "Тасвир ва шарҳи расмҳо",
  },
  travel_agency: {
    en: "Travel agency",
    ru: "Туристическое агентство",
    tj: "Ангенти сайёҳӣ",
  },
  car_repair: {
    en: "Car repair",
    ru: "Ремонт автомобилей",
    tj: "Таъмири мошин",
  },
  baby_care: { en: "Baby care", ru: "Уход за детьми", tj: "Нигоҳубини кӯдак" },
  work_with_electronics: {
    en: "Work with electronics",
    ru: "Работа с электроникой",
    tj: "Кор бо электроника",
  },
  music: { en: "Music", ru: "Музыка", tj: "Мусиқӣ" },
  work_with_wood: {
    en: "Work with wood",
    ru: "Работа с древесиной",
    tj: "Кор бо чӯб",
  },
  the_forest: { en: "The forest", ru: "Лес", tj: "Ҷангал" },
  nurse: { en: "Nurse", ru: "Медсестра", tj: "Ҳамшираи шафқат" },
  tips_for_work: {
    en: "Tips for work",
    ru: "Советы по работе",
    tj: "Маслиҳатҳо нисбати кор",
  },
  being_a_shop_owner: {
    en: "Being a shop owner",
    ru: "Быть владельцем магазина",
    tj: "Соҳиби мағоза будан",
  },
  physical_education: {
    en: "Physical education",
    ru: "Физическая культура",
    tj: "Тарбияи ҷисмонӣ",
  },
  doctor: { en: "Doctor", ru: "Врач", tj: "Духтур" },
  land_surveying_and_land_development: {
    en: "Land surveying and land development",
    ru: "Землеустройство и разработка земельных участков",
    tj: "Заминченкунӣ ва заминсозӣ",
  },
  be_in_a_music_band: {
    en: "Be in a music band",
    ru: "Быть участником музыкальной группы",
    tj: "Дар гурӯҳи мусиқинавозон будан",
  },
  photography: { en: "Photography", ru: "Фотография", tj: "Суратгирӣ" },
  botany: { en: "Botany", ru: "Ботаника", tj: "Ботаника" },
  home_loan_approval_as_a_bank_employee: {
    en: "Home loan approval (as a bank employee)",
    ru: "Оформление кредита на покупку жилья (в качестве сотрудника банка)",
    tj: "Тасдиқӣ қарзи хона (чун коргари бонк)",
  },
  helping_patients_in_the_hospital: {
    en: "Helping patients in the hospital",
    ru: "Помощь пациентам в больнице",
    tj: "Ба беморон ёри расонидан дар беморхона",
  },
  economic_sciences: {
    en: "Economic sciences",
    ru: "Экономические науки",
    tj: "Илмҳои иқтисодӣ",
  },
  physics: { en: "Physics", ru: "Физика", tj: "Физика" },
  chemistry: { en: "Chemistry", ru: "Химия", tj: "Кимиё" },
  electronics: { en: "Electronics", ru: "Электроника", tj: "Электроника" },
  dealing_with_law_and_order: {
    en: "Dealing with law and order",
    ru: "Работа с законом и порядком",
    tj: "Кор бо ҳуқуқ ва қонун",
  },
  artist_folk_crafts: {
    en: "Artist, folk crafts",
    ru: "Художник, народные промыслы",
    tj: "Санъаткор, ҳунарҳои мардумӣ",
  },
  construction: { en: "Construction", ru: "Строительство", tj: "Биносозӣ" },
  accounting: {
    en: "Accounting",
    ru: "Бухгалтерский учет",
    tj: "Муҳосибот (ҳисобу китоби пул)",
  },
  geology: { en: "Geology", ru: "Геология", tj: "Геология" },
  economic_analysis: {
    en: "Economic analysis",
    ru: "Экономический анализ",
    tj: "Таҳлили иқтисодӣ",
  },
  working_with_electricity: {
    en: "Working with electricity",
    ru: "Работа с электричеством",
    tj: "Кор бо барқ",
  },
  which_statement_best_describes_your_familys_financial_situation_when_you_were_14_or_15_years_old:
    {
      en: "Which statement best describes your family's financial situation when you were 14 or 15 years old?",
      ru: "Какое утверждение лучше всего описывает материальное положение вашей семьи, когда вам было 14 или 15 лет?",
      tj: "Кадом изҳорот вазъи молиявии оилаи шуморо ҳангоми 14 ё 15-солагиатон беҳтар тавсиф мекунад?",
    },
  for_each_question_choose_the_option_that_best_suits_you: {
    en: "For each question, choose the option that best suits you.",
    ru: "Для каждого вопроса выберите наиболее подходящий для Вас вариант.",
    tj: "Барои ҳар як савол, вариантеро интихоб кунед, ки ба шумо бештар мувофиқ аст.",
  },
  there_was_not_always_enough_money_in_the_family_even_for_food: {
    en: "There was not always enough money in the family, even for food.",
    ru: "В семье не всегда хватало денег даже на еду.",
    tj: "На ҳамеша пул мерасид дар оила, ҳатто барои ғизо.",
  },
  we_had_money_for_food_but_buying_clothes_was_a_serious_problem_for_us: {
    en: "We had money for food, but buying clothes was a serious problem for us.",
    ru: "У нас были деньги на еду, но покупка одежды была для нас серьезной проблемой.",
    tj: "Барои ғизо пул доштем, аммо харидани либос барои мо мушкили ҷиддӣ буд.",
  },
  we_had_enough_money_for_food_and_clothes_but_we_had_to_save_or_borrow_money_to_buy_a_refrigerator_or_a_washing_machine:
    {
      en: "We had enough money for food and clothes, but we had to save or borrow money to buy a refrigerator or a washing machine.",
      ru: "Денег хватало на еду и одежду, но для покупки холодильника или стиральной машины приходилось экономить или занимать деньги.",
      tj: "Мо барои хӯроку либос пули кофӣ доштем, аммо барои харидани яхдон ё мошини ҷомашӯи бояд захира мекардем ё қарз.",
    },
  without_a_loan_we_could_easily_buy_household_appliances_but_it_was_difficult_to_buy_a_car: {
    en: "Without a loan, we could easily buy household appliances, but it was difficult to buy a car.",
    ru: "Без кредита мы могли легко купить бытовую технику, но купить автомобиль было сложно.",
    tj: "Бе қарзи пулӣ асбобҳои маишии рӯзгорро ба осонӣ харида метавонистем, аммо харидани мошини сабукрав мушкил буд.",
  },
  we_could_buy_a_car_without_any_problems_but_buying_a_new_house_was_difficult: {
    en: "We could buy a car without any problems, but buying a new house was difficult.",
    ru: "Мы могли купить машину без проблем, но покупка нового дома была сложной.",
    tj: "Мошини сабукравро ҳам харида метвонистем бе мушкилӣ, аммо хонаи нав харидан мушкил буд.",
  },
  we_had_no_financial_problems_we_could_even_buy_a_new_house_if_we_wanted_to: {
    en: "We had no financial problems, we could even buy a new house if we wanted to.",
    ru: "У нас не было финансовых проблем, мы даже могли купить новый дом, если бы захотели.",
    tj: "Мо ягон мушкилии молиявӣ надоштем, ҳатто хонаи нав мехаридем, агар мехостем.",
  },

  which_of_the_following_statements_best_describes_your_fathers_employmentwork_situation_when_you_were_15_years_old:
    {
      en: "Which of the following statements best describes your father's employment/work situation when you were 15 years old?",
      ru: "Какое из следующих утверждений лучше всего описывает ситуацию с занятостью/работой Вашего отца, когда Вам было 15 лет?",
      tj: "Кадоме аз гуфтаҳои зерин аз ҳама бештар вазъи шуғли /кори падаратонро инъикос менамояд, ҳангоме ки Шумо 15 сола будед?",
    },
  officialregistered_employee_work_for_a_stranger_but_not_your_own_family_business: {
    en: "Official/registered employee (work for a stranger, but not your own family business)",
    ru: "Официальный/зарегистрированный работник (работа на чужого человека, но не на свой семейный бизнес)",
    tj: "Корманди расмӣ\\ сабт гардида (кор ба шахси бегона, аммо на бизнеси оилаи худ)",
  },
  informalunregistered_worker_work_for_a_stranger_but_not_for_your_family_business: {
    en: "Informal/unregistered worker (work for a stranger, but not for your family business)",
    ru: "Неофициальный/незарегистрированный работник (работа на постороннего человека, но не на свой семейный бизнес)",
    tj: "Корманди ғайрирасмӣ/сабт нагардида (кор ба шахси бегона, аммо на ба бизнеси оилаи худ)",
  },
  working_as_an_employee_or_helper_in_your_familys_nonagricultural_businessshopoutlet: {
    en: "Working as an employee or helper in your family's non-agricultural business/shop/outlet",
    ru: "Работа в качестве работника или помощника в несельскохозяйственном бизнесе/магазине/магазине вашей семьи",
    tj: "Кор кардан ҳамчун корманд ё ёрдамчӣ дар бизнеси ғайри-кишоварзӣ/мағоза/нуқтаи савдои оилаи худ",
  },
  work_as_an_employee_or_assistant_on_your_family_farm: {
    en: "Work as an employee or assistant on your family farm",
    ru: "Работа в качестве работника или помощника на семейной ферме",
    tj: "Кор кардан ҳамчун корманд ё ёрдамчӣ дар хоҷагии фермери(ферма, чорвопарварӣ)-и оилаи худ",
  },
  selfemployedemployed_agricultural_or_nonagricultural: {
    en: "Self-Employed/Employed (Agricultural or Non-Agricultural)",
    ru: "Самозанятые/Занятые (сельское хозяйство или несельское хозяйство)",
    tj: "Корманд бо суратҳисоби худӣ/соҳибкори хусусӣ/кордеҳ (дар соҳаи кишоварзӣ ё ғайрикишоварзӣ)",
  },
  unemployed_looking_for_work: {
    en: "Unemployed (looking for work)",
    ru: "Безработные (ищущие работу)",
    tj: "Бекор (дар ҷустуҷӯи кор буд)",
  },
  doing_housework_including_childcare_taking_care_of_other_family_members: {
    en: "Doing housework (including childcare, taking care of other family members)",
    ru: "Выполняет работу по дому (включая уход за детьми, уход за другими членами семьи)",
    tj: "Ба корҳои хона машғул (аз ҷумла барои парастории кӯдакон, нигоҳубини дигар аъзои хонавода)",
  },
  he_did_not_work_due_to_limited_physical_ability_or_illness: {
    en: "He did not work due to limited physical ability or illness",
    ru: "Не работал из-за ограниченной физической возможности или болезни",
    tj: "Аз сабаби маҳдуд будани қобилияти ҷисмонӣ ё беморӣ кор намекард",
  },
  died: { en: "Died", ru: "Умер", tj: "Фавтида" },
  i_dont_know_refusal_no_answer: {
    en: "I don't know / Refusal / No answer",
    ru: "Не знаю / Отказ / Нет ответа",
    tj: "Намедонам / Раддия/Ҷавоб нест",
  },

  indicate_your_fathers_highest_level_of_education_when_you_were_15_years_old: {
    en: "Indicate your father's highest level of education when you were 15 years old.",
    ru: "Укажите наивысший уровень образования Вашего отца, когда Вам было 15 лет.",
    tj: "Сатҳи баландтарини таҳсилоти падаратонро, вақте ки Шумо 15-сола будед, нишон диҳед.",
  },
  no_formal_education: {
    en: "No formal education",
    ru: "Нет формального образования",
    tj: "Бе таҳсилоти расмӣ",
  },
  general_primary_education_grades_14: {
    en: "General Primary Education (Grades 1-4)",
    ru: "Общее начальное образование (1-4 классы)",
    tj: "Таҳсилоти умумии ибтидоӣ (Синфҳои 1-4)",
  },
  secondary_general_education_grades_59: {
    en: "Secondary General Education (Grades 5-9)",
    ru: "Среднее общее образование (5-9 классы)",
    tj: "Таҳсилоти умумии миёнаи нопурра (Синфҳои 5-9)",
  },
  complete_secondary_general_education_grades_1011: {
    en: "Complete secondary general education (Grades 10-11)",
    ru: "Полное среднее общее образование (10-11 классы)",
    tj: "Таҳсилоти умумии миёнаи пурра (Синфҳои 10-11)",
  },
  primary_vocational_education_ptu_or_secondary_vocational_education_technikum: {
    en: "Primary vocational education (PTU) or secondary vocational education (Technikum)",
    ru: "Начальное профессиональное образование (ПТУ) или среднее профессиональное образование (Техникум)",
    tj: "Таҳсилоти ибтидоии касбӣ (ПТУ) ё миёнаи касбӣ (Техникум)",
  },
  higher_education_university_conservatory_academy_or_postgraduate_doctorate: {
    en: "Higher education (University, Conservatory, Academy) or Postgraduate, Doctorate",
    ru: "Высшее образование (университет, консерватория, академия) или аспирантура, докторантура",
    tj: "Таҳсилоти олӣ (Донишгоҳ, Консерватория, Академия) ё Аспирантура, Докторантура",
  },
  dont_know_no_answer: {
    en: "Don't know / No answer",
    ru: "Не знаю / Нет ответа",
    tj: "Намедонам / Ҷавоб нест",
  },

  which_of_the_following_best_describes_your_mothers_employment_status_when_you_were_15_years_old: {
    en: "Which of the following best describes your mother's employment status when you were 15 years old?",
    ru: "Что из перечисленного ниже лучше всего описывает статус занятости Вашей матери, когда Вам было 15 лет?",
    tj: "Кадоме аз гуфтаҳои зерин аз ҳама бештар вазъи шуғли/кори модаратонро, ҳангоме ки Шумо 15 сола будед, инъикос менамояд?",
  },
  informalunregistered_worker_work_for_a_stranger_but_not_for_ones_own_family: {
    en: "Informal/unregistered worker (work for a stranger, but not for one's own family)",
    ru: "Неофициальный/незарегистрированный работник (работа на незнакомого человека, но не на собственную семью)",
    tj: "Корманди ғайрирасмӣ/сабт нагардида (кор ба шахси бегона, аммо на ба оилаи худ)",
  },
  work_as_an_employee_or_helper_in_your_familys_farm_farm_animal_husbandry: {
    en: "Work as an employee or helper in your family's farm (farm, animal husbandry)",
    ru: "Работа в качестве работника или помощника в хозяйстве своей семьи (ферма, животноводство)",
    tj: "Кор кардан ҳамчун корманд ё ёрдамчӣ дар хоҷагии қишлоқи (ферма, чорвопарварӣ) оилаи худ",
  },
  selfemployedselfemployedemployed_agricultural_or_nonagricultural: {
    en: "Self-Employed/Self-Employed/Employed (Agricultural or Non-Agricultural)",
    ru: "Самозанятый/самостоятельно работающий/занятый (сельскохозяйственный или несельскохозяйственный)",
    tj: "Корманд бо суратҳисоби худӣ/соҳибкори хусусӣ/кордеҳ (дар соҳаи кишоварзӣ ё ғайрикишоварзӣ)",
  },
  unemployed_available_for_work_and_looking_for_work: {
    en: "Unemployed (available for work and looking for work)",
    ru: "Безработные (доступные для работы и ищущие работу)",
    tj: "Бекор (дастрас барои иҷрои кор ва дар ҷустуҷӯи кор)",
  },
  she_did_not_work_due_to_his_limited_physical_capacity_and_illness: {
    en: "She did not work due to his limited physical capacity and illness",
    ru: "Не работает в связи с ограниченными физическими возможностями и болезнью",
    tj: "Аз сабаби маҳдуд будани қобилияти ҷисмонӣ е беморӣ кор намекард",
  },
  pensioner: { en: "Pensioner", ru: "Пенсионер", tj: "Нафақахур" },

  indicate_your_mothers_highest_level_of_education_when_you_were_15_years_old: {
    en: "Indicate your mother's highest level of education when you were 15 years old.",
    ru: "Укажите наивысший уровень образования Вашей матери, когда Вам было 15 лет.",
    tj: "Сатҳи баландтарини таҳсилоти модаратонро , вақте ки Шумо 15-сола будед, нишон диҳед.",
  },
  the_result_of_the_test_test_for_choosing_a_professioncluster_using_artificial_intelligence: {
    en: 'The result of the test "Test for choosing a profession/cluster using Artificial Intelligence"',
    ru: 'Результат теста "Тест на выбор профессии/кластера с использованием искусственного интеллекта"',
    tj: 'Натиҷаи тест "Тест барои интихоби касб/кластер бо истифода аз Зеҳни сунъӣ"',
  },
  the_result_of_the_test: {
    en: "The result of the test",
    ru: "Результат теста",
    tj: "Натиҷаи тест",
  },
  test_time_started: {
    en: "Test started: ",
    ru: "Тест начат: ",
    tj: "Тест оғоз шуд: ",
  },
  test_time_spent: {
    en: "Time spent: ",
    ru: "Затраченное время: ",
    tj: "Вақт сарф шуд: ",
  },
  matching_clusters_and_matching_levels: {
    en: "Matching clusters and matching levels",
    ru: "Сопоставление кластеров и уровней сопоставления",
    tj: "Кластерҳои мувофиқ ва сатҳи мувофиқат",
  },
  natural_and_technical: {
    en: "Natural and technical",
    ru: "Естественные и технические",
    tj: "Табиӣ ва техникӣ",
  },
  economy_and_geography: {
    en: "Economy and geography",
    ru: "Экономика и география",
    tj: "Иқтисод ва география",
  },
  philology_pedagogy_and_art: {
    en: "Philology, pedagogy, and art",
    ru: "Филология, педагогика и искусство",
    tj: "Филология, педагогика ва санъат",
  },
  sociology_and_law: {
    en: "Sociology and law",
    ru: "Социология и право",
    tj: "Ҷомеашиносӣ ва ҳуқуқ",
  },
  medicine_biology_and_sports: {
    en: "Medicine, biology, and sports",
    ru: "Медицина, биология и спорт",
    tj: "Тиб, биология ва варзиш",
  },
  review_of_suitable_occupations: {
    en: "Review of suitable occupations",
    ru: "Обзор подходящих профессий",
    tj: "Азназаргузаронии касбу корҳои мувофиқ",
  },
};
