import { createContext } from "react"

export type SidebarOptions = {
  component?: JSX.Element
  width?: string
  closeOnEsc?: boolean
  closeOnOutsideClick?: boolean
  onClose?: () => void
}

export type SidebarContextType = {
  open(options?: SidebarOptions): void
  close(resetOptions?: boolean): void
  setOptions(options: SidebarOptions): void
}

export const SidebarContext = createContext<SidebarContextType>({
  open() {},
  close() {},
  setOptions() {},
})
