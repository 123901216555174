/// <reference path='../typings.d.ts' />

export const AppConfig = {
  // host: "http://localhost:7070",
  host: "http://zyplcareer.pro/api",
  domain: "http://localhost:3000",
  port: 3000,
  apiEndpoint: import.meta.env.VITE_API_ENDPOINT,
  jwtSecret:
    "751db26ea0d648458af454a9142ff9c9b7c12d2ac653c1336b85a669897ac36652935ab0017ebf41df68ac042e24f6201e2b1dcc14c734eeaa69f12bbb4818dd",
};
