import React, { useContext, useState } from "react";
import dayjs from "dayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { LanguageContext } from "../../context/language-context/language.context";
import { Input } from "../../component/input/input.component";
import { text } from "../../text";
import styles from "./sign-up.styles.module.css";
import { Button } from "../../component/button/button.component";
import { Icon } from "../../component/icon/icon.component";
import { LoadingSpinner } from "../../component/loading-spinner/loading-spinner.component";
import { sendPostRequest } from "../../util/send-post-request";
import { toast } from "react-toastify";

export default function SignUpComponent() {
  // ---------------------------------------------------------------------------
  // variables
  // ---------------------------------------------------------------------------
  const { lang } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
    email: "",
    dateOfBirth: moment(dayjs("2002-04-17").toDate()).format("DD/MM/YYYY"),
    city: "",
    gender: "",
    password: "",
    repassword: "",
    code: "",
  });

  const [error, setError] = useState("");

  const [typePassword, setTypePassword] = useState({
    password: true,
    repassword: true,
  });
  const [hasEmail, setHasEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  // ---------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------

  // ---------------------------------------------------------------------------
  // functions
  // ---------------------------------------------------------------------------

  async function hendleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (data.password !== data.repassword) {
      setError(text.passwords_do_not_match[lang]);
      return;
    }
    setLoading(true);
    const res = await sendPostRequest("/user/has", { email: data.email });
    if (res) {
      const json = await res.json();
      if (json.status === 200) {
        toast.error("User already registered", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (json.status === 404) {
        setHasEmail(true);

        const resSendMessageToEmail = await sendPostRequest("/verify/email", {
          email: data.email,
        });
        if (resSendMessageToEmail) {
          const sendMessageEmailJson = await resSendMessageToEmail.json();
          if (sendMessageEmailJson.status === 200) {
            return;
          }
        }
      }
    }
    setLoading(false);
  }

  // ---------------------------------------------------------------------------
  return (
    <div className={styles.container}>
      {!hasEmail ? (
        <form className={styles.modal_container} onSubmit={hendleSubmit}>
          <h3>{text.registration[lang]}</h3>
          <label htmlFor="name" className={styles.modal_container_label}>
            <span>{text.name[lang]}</span>
            <Input
              mode="primary"
              id="name"
              icon="fa-solid fa-x"
              onIconClick={() => {
                setData({
                  ...data,
                  name: "",
                });
              }}
              onValueChange={(e) => {
                setData({
                  ...data,
                  name: e,
                });
              }}
              value={data.name}
              required
            />
          </label>
          <label htmlFor="email" className={styles.modal_container_label}>
            <span>Email</span>
            <Input
              type="email"
              mode="primary"
              id="email"
              icon="fa-solid fa-x"
              onValueChange={(e) => {
                setData({
                  ...data,
                  email: e,
                });
              }}
              value={data.email}
              onIconClick={() => {
                setData({
                  ...data,
                  email: "",
                });
              }}
              required
            />
          </label>
          <div
            style={{
              display: "flex",
              width: "90%",
              gap: "10px",
              marginBottom: "10px",
            }}
          >
            <DemoItem label={`${text.date_of_birth[lang]}`}>
              <MobileDatePicker
                defaultValue={dayjs("2002-04-17")}
                onChange={(e) => {
                  setData({
                    ...data,
                    dateOfBirth: moment(e?.toDate()).format("DD/MM/YYYY"),
                  });
                }}
              />
            </DemoItem>

            <label htmlFor="city" className={styles.modal_container_label}>
              <span>{text.city_district_of_residence[lang]}</span>
              <select
                id="city"
                onChange={(e) => {
                  setData({ ...data, city: e.target.value });
                }}
                value={data.city}
                required
              >
                <option value="">{text.choose[lang]}</option>
                <option value="3">И. Сомони (Душанбе)</option>
                <option value="1">Сино (Душанбе)</option>
                <option value="2">Фирдавси (Душанбе)</option>
                <option value="4">Шохмансур (Душанбе)</option>
                <option value="6">Айни (Согд)</option>
                <option value="7">Бустон (Согд)</option>
                <option value="5">Худжанд (Согд)</option>
                <option value="8">Бохтар (Хатлон)</option>
                <option value="11">Кулоб (Хатлон)</option>
                <option value="10">Кушониен (Хатлон)</option>
                <option value="9">Леваканд (Хатлон)</option>
                <option value="13">Истаравшан (ГБАО)</option>
                <option value="12">Хорог (ГБАО)</option>
              </select>
            </label>
          </div>

          <label htmlFor="gender" className={styles.modal_container_label}>
            <span>{text.select_gender[lang]}</span>
            <select
              id="gender"
              onChange={(e) => {
                setData({ ...data, gender: e.target.value });
              }}
              value={data.gender}
              required
            >
              <option value={""}>{text.choose[lang]}</option>
              <option value="male">{text.man[lang]}</option>
              <option value="female">{text.woman[lang]}</option>
            </select>
          </label>

          <label htmlFor="passwrod" className={styles.modal_container_label}>
            <span>{text.password[lang]}</span>
            <Input
              mode="primary"
              type={typePassword.password ? "password" : "text"}
              id="passwrod"
              icon={
                typePassword.password
                  ? "fa-regular fa-eye"
                  : "fa-regular fa-eye-slash"
              }
              onIconClick={() => {
                setTypePassword({
                  ...typePassword,
                  password: !typePassword.password,
                });
              }}
              onValueChange={(e) => {
                setData({
                  ...data,
                  password: e,
                });
              }}
              value={data.password}
              required
            />
          </label>

          <label htmlFor="repassword" className={styles.modal_container_label}>
            <span>{text.repeat_password[lang]}</span>
            <Input
              mode="primary"
              id="repassword"
              type={typePassword.repassword ? "password" : "text"}
              icon={
                typePassword.repassword
                  ? "fa-regular fa-eye"
                  : "fa-regular fa-eye-slash"
              }
              onValueChange={(e) => {
                setData({
                  ...data,
                  repassword: e,
                });
              }}
              value={data.repassword}
              onIconClick={() => {
                setTypePassword({
                  ...typePassword,
                  repassword: !typePassword.repassword,
                });
              }}
              required
            />
          </label>
          <p style={{ color: "red" }}>{error}</p>
          <Button mode="primary">
            {loading ? (
              <LoadingSpinner />
            ) : (
              <>
                {text.registration[lang]}
                <Icon name="fa-solid fa-arrow-right" size="small" />
              </>
            )}
          </Button>

          <div
            style={{
              width: "90%",
              height: "20px",
              borderBottom: "1px solid silver",
            }}
          ></div>

          <p
            style={{
              cursor: "pointer",
              color: "blue",
            }}
            onClick={() => {
              navigate("/login");
            }}
          >
            {text.log_in_to_the_system[lang]}
          </p>
        </form>
      ) : (
        <div className={styles.modal_container}>
          <h3>{text.confirm_email[lang]}</h3>
          <Input
            mode="primary"
            type="text"
            placeholder="code"
            onValueChange={(e) => {
              const regex = /^[0-9]*$/;
              const newInputValue = e;
              if (
                newInputValue === "" ||
                (regex.test(newInputValue) && newInputValue.length < 5)
              ) {
                setData({
                  ...data!,
                  code:
                    newInputValue !== ""
                      ? String(Number(newInputValue))
                      : newInputValue,
                });
              }
            }}
            value={data.code}
          />
          <p
            style={{
              color: "#8592aa",
            }}
          >
            {text.we_have_sent[lang]}
          </p>
          <Button
            onClick={async () => {
              setLoading(true);
              const res = await sendPostRequest("/user/register", data);
              if (res) {
                const json = await res.json();
                if (json.status === 201) {
                  toast.success(`${text.user_successfully[lang]}`, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  navigate("/login");
                } else if (json.status === 400) {
                  toast.error(`${text.your_code_is_wrong[lang]}`, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else if (json.status === 404) {
                  toast.error("Can't find email", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else if (json.status === 500) {
                  toast.error("Server error", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              }
              setLoading(false);
            }}
            disabled={loading}
          >
            {loading ? <LoadingSpinner /> : text.confirm[lang]}
          </Button>
        </div>
      )}
    </div>
  );
}
