import { createContext } from "react"
import { PopupOpenerProps } from "./popup.types"

export type PopupOpenerContextType = {
  open({ offset, content, popupStyle }: PopupOpenerProps): void
  close(): void
  setOptions(options: PopupOpenerProps): void
}

export const PopupOpenerContext = createContext<PopupOpenerContextType>({
  open() {},
  close() {},
  setOptions() {},
})
