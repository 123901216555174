import ReactDOM from "react-dom/client";
import AppComponent from "./app/app.component.tsx";
import "./index.css";
import { ModalProvider } from "./component/modal";
import { SidebarProvider } from "./component/sidebar";
import { AuthProvider } from "./context/auth-context/auth.provider.tsx";
import { ThemeProvider } from "./context/theme-context/theme.provider.tsx";
import { LanguageProvider } from "./context/language-context/language.provider.tsx";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AlertProvider } from "./component/alert/alert.provider.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <BrowserRouter>
    <AuthProvider>
      <ThemeProvider>
        <LanguageProvider>
          <SidebarProvider>
            <ModalProvider>
              <AlertProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <AppComponent />
                </LocalizationProvider>
              </AlertProvider>
            </ModalProvider>
          </SidebarProvider>
        </LanguageProvider>
      </ThemeProvider>
    </AuthProvider>
  </BrowserRouter>
);
